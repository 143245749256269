import React from 'react';
import './About.css';
import Skill from './Skill.js';

function About() {
    return(
        <div className="about-background">
            <div className="row skill-row">
                <div className="col-md-6 skill-coloumn p-0">
                    <Skill name="" text="C#, .NET, OOP" icon="devicon-csharp-line-wordmark"/>
                    <Skill name="" text="Networking, Gameplay, ML, 3D" icon="fab fa-unity"/>
                    <Skill name="" text="Javascript, CSS, HTML" icon="devicon-javascript-plain"/>
                </div>
                <div className="col-md-6 skill-coloumn p-0">
                    <Skill name="" text="Scripting and Testing with Python" icon="devicon-python-plain"/>
                    <Skill name="" text="Algorithms, Pathfinding, Mass Data" icon="fas fa-wave-square"/>
                    <Skill name="" text="React, Bootstrap, NAudio, LibNoise" icon="fas fa-book"/>
                </div>
            </div>
            <h3 className="title">About Me</h3>
            <p className="about-description">
                Hi! My name is Christian Carlburg and I like to write code and make things. 
                I’m currently based in the Phoenix, AZ area, but I’ve been around over the 
                years, and I’ve been programming throughout them. I’ve had a long lived goal, 
                and this website is made to showcase my progress to that end. That goal is to be able 
                to write code for a living, and it’s time that goal is achieved. With everyone 
                spending more time at home, I finally ran out of excuses to make an effort 
                so I got to work and you are looking at the result. The purpose of this 
                website is to compile and showcase some of my projects from the recent years 
                for anyone to see. So how about it? As I said, I like to write code 
                and make things. What can I make you?
            </p>
        </div>
    );
}

export default About