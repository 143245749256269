import React from 'react';
import RNavbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Navbar.css';
import icon from '../img/icon.png';

function Navbar() {
    return (
        <RNavbar collapseOnSelect expand="md" bg="dark" variant="dark" className="container-fluid">
            <div className="row no-gutter nav-container">
                <RNavbar.Toggle aria-controls="responsive-navbar-nav" />
                <RNavbar.Brand href="/"><img src={icon} alt="christian Carlburg" className="logo" /></RNavbar.Brand>
                <RNavbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto" justify="true">
                        <Nav.Link href="/">Home</Nav.Link>
                        <NavDropdown title="Projects" href="/projects/" id="collasible-nav-dropdown">                        
                            <NavDropdown.Item href="/projects/DSP">Digital Signal Processing</NavDropdown.Item>
                            <NavDropdown.Item href="/projects/CraftCraft">CraftCraft</NavDropdown.Item>
                            <NavDropdown.Item href="/projects/FigureFighter">Figure Fighter</NavDropdown.Item>
                            <NavDropdown.Item href="/projects/OtherTestimonials">Other Projects</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </RNavbar.Collapse>

            </div>
        </RNavbar>
    );
}

export default Navbar