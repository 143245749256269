import React, {useState} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './ProjectCarousel.css';

function ProjectCarousel(props) {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    //Create inner slides
    const innerSlides = props.slides.map((slide, i) => {
        return (
            <Carousel.Item key={i}>
                {slide}
            </Carousel.Item>
        );
    });

    return (
        <div className="carousel-background">
            <Carousel activeIndex={index} onSelect={handleSelect}>
                { innerSlides }
            </Carousel>
        </div>
    );
}

export default ProjectCarousel;