import React from 'react';
import './Projects.css';
import headerImg from '../img/image5.png';
import itemImg from '../img/image1.png';
import invItemImg from '../img/image7.png';
import invImg from '../img/image2.png';

function CraftCraft() {
    return (
        <div className="row m-0 content">
            <div className="col content-col">
                <h1 className="w-100 header">CraftCraft</h1>
                <img src={headerImg} alt="" className="col-image" />
                <h3>What's this?</h3>
                <p>                    
                    This project, nicknamed Craftcraft, was a simple weekend project
                    (which only took me 2-4 weekends!). The purpose of this project was
                    that I wanted to create a toolset that could fill some ubiquitous
                    need for game projects. For that, I considered a grid based inventory
                    system to be a perfect candidate and a crafting system would add a little
                    extra challenge and intrigue (...and the second weekend) to pad out the
                    project. It's important that this system is flexible, extensible, and
                    decently optimized as I want to be able to be used in other projects
                    with relative ease and little overhead.
                </p>
                <h3>The Inventory System</h3>
                <p>
                    <h5>Overview</h5>
                    The inventory system is made up of a few different object types:
                    <ul>
                        <li>Item (note that I refer to these objects with a capital letter and will use the word in general cases with a lowercase)</li>
                        <li>InventoryItem</li>
                        <li>Inventory</li>
                    </ul>
                    The item object is simply a template object to interface with which
                    defines the different forms an Item can take within a game. It is
                    important to have the item data be separated into different objects
                    because an item can exist in more than one way in games. In game, an
                    item may exist as a sprite in your bag but when you drop it it becomes
                    a 3D object in the game world. This means you take one object, then
                    convert it to a new type in a different context, although based on the
                    same underlying data. Item is intended to be extended to add more
                    implementations and types other than InventoryItem can be added, which
                    is the only one defined for now. Item inherits from Unity's ScriptableObject
                    which provides the ability to manage the items and means they can even be
                    tracked by source control. The Inventory object is your box standard
                    inventory grid. It contains fields for a max grid size, max stack allowance,
                    and a runtime collection of InventoryItems objects for everything currently
                    contained. Most importantly though, Inventory contains most of the functions
                    that manage each item's state.
                </p>
                <p>
                    <h5>Item</h5>
                    <img src={itemImg} alt="" className="inline-image" />
                    There's not much to this one. For the sake of the whole system though, it may
                    help to think of this object representing the concept of what an item is rather
                    than the item itself. Meaning that it represents the essence of an item instead
                    of anything you actually see on screen. Although, I admit that unless you can
                    already glean some of the finer points of the system from what I described already
                    you may be left more confused by that than before. So, for a practical explanation
                    I will refer to what I stated above, Item is simply a set of defined fields for the
                    item which are defined states of the Item. As for what these fields are, they can
                    and should be changed to suit the needs of the individual project but for this one,
                    they include the name, the sprite, the grid size, and so on. The importance of having
                    this data defined on its own is that it allows for a conversion of an item of one
                    context to another context; say from an InventoryItem object, to a currently undefined
                    WorldObject for example. It also allows for code to be able to handle all items
                    regardless of context when you don't need any of the context-specific data. I use this
                    to effect in the Add/Remove functions of the Inventory which I will expand on below.
                </p>
                <p>
                    <h5>InventoryItem</h5>
                    This object is nearly as simple as Item is, and the most important concept here is how 
                    they relate to each other. If Item represents an item's essence, the InventoryItem 
                    represents the manifested item on screen that you can see within the inventory. InventoryItem 
                    implements Item, meaning that it is made up of all of the fields defined from Item plus 
                    anything that is needed for the context of the inventory container. For these context-specific 
                    fields we have grid position, a reference to the Inventory in which it resides, and a coroutine 
                    reference to act as its current state in terms of dragging (for drag and drop sprites).
                    <img src={invItemImg} alt="" className="col-image" />
                </p>
                <p>
                    <h5>Inventory</h5>
                    Inventory is where the magic happens because this is where everything is actually used. It 
                    contains functions for the adding and removing of items which was overloaded to take either 
                    an Item or an InventoryItem for flexibility. It also contains the code which builds the grid 
                    for the inventory space. I implemented this using unity's GUI tools. The approach to this has 
                    an upside and downside. The downside is that you need to create a prefab structured in a particular 
                    way so that code finds what it is expecting. The upside is that you can maintain artistic control 
                    while having the tedious parts being constructed for you at runtime.

                    <img src={invImg} alt="" className="col-image" />

                    Inventory contains a field which references an UI Inventory element that contains a single cell. The 
                    pixel size of the UI Inventory is maintained and the cell object gets resized and multiplied to fill 
                    out the grid according to the pixel size of the UI Inventory and the grid size of the Container. The 
                    UI Inventory also detects clicks/drags and uses the mouse position to determine which item is being 
                    clicked to send the appropriate events.
                </p>
                <h3>The Crafting System</h3>
                <p>
                    <h5>Contextual Positioning</h5>
                    I referred to the specific style of crafting I implemented as contextual positioning in my mind as I 
                    developed the feature. The crafting behaviour was modeled off the crafting system found in Minecraft. 
                    The logic of the system is not very complex and essentially revolves around a single object type. I 
                    called this type a Recipe and it is defined by an input InventoryItem collection and an output InventoryItem 
                    collection. The positions of these collections would be treated as relative and used to detect a matching 
                    Recipe anywhere within any Inventory that had the capability of crafting. A match would be detected by 
                    looping over each item in the Inventory and if anything matches the item in the first position of a 
                    Recipe the algorithm proceeds to evaluate if there is a match to the rest of that Recipe. This part is 
                    done by taking the item positions from the potentially matching pattern and seeing if the relative 
                    positions have matching items in the positions relative to the initial matching check. If everything 
                    checks out, then a match is found and the appropriate event is called to create the newly crafted item 
                    and its constituents can be consumed. There is a loop within a loop for this approach which leaves room 
                    for improvement on this algorithm but since everything was implemented with events, and little to no checks 
                    on every frame, it worked quite well and I am happy with the result. 
                </p>
                <h3>All in All</h3>
                <p>
                    I think this project ended up being quite the success and I am pleased with the result. I don't believe I 
                    created anything revolutionary, but the system is extensible, can be customized enough to fill several roles 
                    'out of the box', performant, and it works. I'm not sure what more you could ask for in any piece of code. 
                    The level of polish achieved in this project is an achievement in my book even if it is on a small scale 
                    and I will certainly use this experience to inform my creation of any dev tools in the future.
                </p>
                <p>
                    There are more features in the Craftcraft project than I could discuss here so if you'd like to check 
                    out all of the extra features and inner workings of the code, check it out on my <a href="https://github.com/sharpshot124/Craftcraft">GitHub!</a>.
                </p>
            </div>
        </div>
    );
}

export default CraftCraft;