const code = `<?xml version="1.0" encoding="utf-8"?>
<CharacterData xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">
  <name>Brawler</name>
  <punch1>
    <curves>
      <item>
        <key>
          <string>Armaturem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.0588889122</value>
                <inTangent>0.04374981</inTangent>
                <outTangent>0.04374981</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.0151391029</value>
                <inTangent>0.250347018</inTangent>
                <outTangent>0.250347018</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.441805124</value>
                <inTangent>0.2162255</inTangent>
                <outTangent>0.2162255</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.4173119</value>
                <inTangent>0.028003931</inTangent>
                <outTangent>0.028003931</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.497813</value>
                <inTangent>0.08050108</inTangent>
                <outTangent>0.08050108</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.5859723</value>
                <inTangent>-0.2819445</inTangent>
                <outTangent>-0.2819445</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.8679168</value>
                <inTangent>-0.02673614</inTangent>
                <outTangent>-0.02673614</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.6394446</value>
                <inTangent>0.1495105</inTangent>
                <outTangent>0.1495105</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.5688958</value>
                <inTangent>-0.0619516075</inTangent>
                <outTangent>-0.0619516075</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.7633478</value>
                <inTangent>-0.194451988</inTangent>
                <outTangent>-0.194451988</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9994357</value>
                <inTangent>-0.02929163</inTangent>
                <outTangent>-0.02929163</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.9701441</value>
                <inTangent>-0.004945487</inTangent>
                <outTangent>-0.004945487</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.989544749</value>
                <inTangent>0.0070950985</inTangent>
                <outTangent>0.0070950985</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.9843343</value>
                <inTangent>-0.00354328752</inTangent>
                <outTangent>-0.00354328752</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.9824582</value>
                <inTangent>-0.0018761158</inTangent>
                <outTangent>-0.0018761158</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.033590395</value>
                <inTangent>-0.2761197</inTangent>
                <outTangent>-0.2761197</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.2425293</value>
                <inTangent>0.055317983</inTangent>
                <outTangent>0.055317983</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.144226372</value>
                <inTangent>0.209420919</inTangent>
                <outTangent>0.209420919</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.176312536</value>
                <inTangent>0.0211283788</inTangent>
                <outTangent>0.0211283788</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.18648313</value>
                <inTangent>0.0101705939</inTangent>
                <outTangent>0.0101705939</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.00252750167</value>
                <inTangent>0.464613348</inTangent>
                <outTangent>0.464613348</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.467140853</value>
                <inTangent>0.00243523717</inTangent>
                <outTangent>0.00243523717</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.0073979795</value>
                <inTangent>-0.220584318</inTangent>
                <outTangent>-0.220584318</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.0259722136</value>
                <inTangent>0.0006586686</inTangent>
                <outTangent>0.0006586686</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.008715317</value>
                <inTangent>-0.0172568969</inTangent>
                <outTangent>-0.0172568969</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.999996841</value>
                <inTangent>0.115813911</inTangent>
                <outTangent>0.115813911</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.88418293</value>
                <inTangent>1.20997429E-05</inTangent>
                <outTangent>1.20997429E-05</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.999972641</value>
                <inTangent>-0.0577398539</inTangent>
                <outTangent>-0.0577398539</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.999662638</value>
                <inTangent>5.30481339E-06</inTangent>
                <outTangent>5.30481339E-06</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.999962032</value>
                <inTangent>-0.000299394131</inTangent>
                <outTangent>-0.000299394131</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.959913552</value>
                <inTangent>-0.0313956738</inTangent>
                <outTangent>-0.0313956738</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.9285179</value>
                <inTangent>-0.976137161</inTangent>
                <outTangent>-0.976137161</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.99236083</value>
                <inTangent>-0.173665345</inTangent>
                <outTangent>-0.173665345</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.5811871</value>
                <inTangent>0.6397985</inTangent>
                <outTangent>0.6397985</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.287236243</value>
                <inTangent>-0.2939509</inTangent>
                <outTangent>-0.2939509</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.280296117</value>
                <inTangent>-0.0909917057</inTangent>
                <outTangent>-0.0909917057</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.371287823</value>
                <inTangent>0.07846322</inTangent>
                <outTangent>0.07846322</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.123369679</value>
                <inTangent>-0.221241087</inTangent>
                <outTangent>-0.221241087</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.81377</value>
                <inTangent>-0.41724506</inTangent>
                <outTangent>-0.41724506</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.9578598</value>
                <inTangent>-0.144089818</inTangent>
                <outTangent>-0.144089818</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.8626374</value>
                <inTangent>0.0172376037</inTangent>
                <outTangent>0.0172376037</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.879875</value>
                <inTangent>-0.8447815</inTangent>
                <outTangent>-0.8447815</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.8269257</value>
                <inTangent>-0.349037349</inTangent>
                <outTangent>-0.349037349</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.1818003</value>
                <inTangent>0.6164543</inTangent>
                <outTangent>0.6164543</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.405982941</value>
                <inTangent>0.224182636</inTangent>
                <outTangent>0.224182636</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.505822837</value>
                <inTangent>-0.0306176841</inTangent>
                <outTangent>-0.0306176841</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.475205153</value>
                <inTangent>-0.534067035</inTangent>
                <outTangent>-0.534067035</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.5623112</value>
                <inTangent>0.254065156</inTangent>
                <outTangent>0.254065156</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.983335555</value>
                <inTangent>0.7380959</inTangent>
                <outTangent>0.7380959</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.913880646</value>
                <inTangent>-0.06945491</inTangent>
                <outTangent>-0.06945491</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.9604014</value>
                <inTangent>0.0230624676</inTangent>
                <outTangent>0.0230624676</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.937338948</value>
                <inTangent>0.1589323</inTangent>
                <outTangent>0.1589323</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.6425368</value>
                <inTangent>0.06779602</inTangent>
                <outTangent>0.06779602</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.8017469</value>
                <inTangent>-0.177521676</inTangent>
                <outTangent>-0.177521676</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.9975802</value>
                <inTangent>-0.195833266</inTangent>
                <outTangent>-0.195833266</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.278620273</value>
                <inTangent>0.06979868</inTangent>
                <outTangent>0.06979868</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.348418951</value>
                <inTangent>0.243817285</inTangent>
                <outTangent>0.243817285</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.766254842</value>
                <inTangent>0.124622405</inTangent>
                <outTangent>0.124622405</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.59766376</value>
                <inTangent>-0.348364085</inTangent>
                <outTangent>-0.348364085</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.06952664</value>
                <inTangent>-0.5281371</inTangent>
                <outTangent>-0.5281371</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.96620214</value>
                <inTangent>-0.180265367</inTangent>
                <outTangent>-0.180265367</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.7859368</value>
                <inTangent>-0.08530757</inTangent>
                <outTangent>-0.08530757</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.795587</value>
                <inTangent>-0.101536691</inTangent>
                <outTangent>-0.101536691</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.5828634</value>
                <inTangent>-0.0129841566</inTangent>
                <outTangent>-0.0129841566</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.7696187</value>
                <inTangent>0.1867553</inTangent>
                <outTangent>0.1867553</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.257785827</value>
                <inTangent>-0.360521168</inTangent>
                <outTangent>-0.360521168</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.618307</value>
                <inTangent>-0.1740268</inTangent>
                <outTangent>-0.1740268</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.605839431</value>
                <inTangent>-0.09713161</inTangent>
                <outTangent>-0.09713161</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.8125702</value>
                <inTangent>-0.01633221</inTangent>
                <outTangent>-0.01633221</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.63850385</value>
                <inTangent>0.174066365</inTangent>
                <outTangent>0.174066365</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9533692</value>
                <inTangent>-0.421022</inTangent>
                <outTangent>-0.421022</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.5323472</value>
                <inTangent>-0.15884757</inTangent>
                <outTangent>-0.15884757</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.635674059</value>
                <inTangent>0.107352108</inTangent>
                <outTangent>0.107352108</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.7470514</value>
                <inTangent>0.09662512</inTangent>
                <outTangent>0.09662512</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.8289243</value>
                <inTangent>0.08187288</inTangent>
                <outTangent>0.08187288</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.301806569</value>
                <inTangent>0.5447195</inTangent>
                <outTangent>0.5447195</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.8465261</value>
                <inTangent>0.235075474</inTangent>
                <outTangent>0.235075474</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.7719575</value>
                <inTangent>-0.09087989</inTangent>
                <outTangent>-0.09087989</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.6647663</value>
                <inTangent>-0.106298327</inTangent>
                <outTangent>-0.106298327</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.559360862</value>
                <inTangent>-0.10540545</inTangent>
                <outTangent>-0.10540545</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.9966414</value>
                <inTangent>0.162333846</inTangent>
                <outTangent>0.162333846</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.834307551</value>
                <inTangent>0.0345886052</inTangent>
                <outTangent>0.0345886052</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.9274642</value>
                <inTangent>-0.08276385</inTangent>
                <outTangent>-0.08276385</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.999835253</value>
                <inTangent>-0.0339001119</inTangent>
                <outTangent>-0.0339001119</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.9952644</value>
                <inTangent>0.004570842</inTangent>
                <outTangent>0.004570842</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.08188956</value>
                <inTangent>0.469409883</inTangent>
                <outTangent>0.469409883</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.551299453</value>
                <inTangent>0.146011278</inTangent>
                <outTangent>0.146011278</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.373912126</value>
                <inTangent>-0.2847258</inTangent>
                <outTangent>-0.2847258</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.0181521457</value>
                <inTangent>-0.138353646</inTangent>
                <outTangent>-0.138353646</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.09720482</value>
                <inTangent>0.115356967</inTangent>
                <outTangent>0.115356967</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>7.473863E-08</value>
                <inTangent>1.46683377E-08</inTangent>
                <outTangent>1.46683377E-08</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>8.940697E-08</value>
                <inTangent>1.16415322E-10</inTangent>
                <outTangent>1.16415322E-10</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>7.497146E-08</value>
                <inTangent>-8.381903E-09</inTangent>
                <outTangent>-8.381903E-09</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>7.264316E-08</value>
                <inTangent>-1.1199154E-07</inTangent>
                <outTangent>-1.1199154E-07</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.49011612E-07</value>
                <inTangent>-2.21654773E-07</inTangent>
                <outTangent>-2.21654773E-07</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.0603703447</value>
                <inTangent>-0.0321550742</inTangent>
                <outTangent>-0.0321550742</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.0282152724</value>
                <inTangent>0.132238835</inTangent>
                <outTangent>0.132238835</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.324848</value>
                <inTangent>0.0858978</inTangent>
                <outTangent>0.0858978</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.200010866</value>
                <inTangent>0.0766092241</inTangent>
                <outTangent>0.0766092241</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.478066444</value>
                <inTangent>0.278055578</inTangent>
                <outTangent>0.278055578</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.998176038</value>
                <inTangent>1.99777794</inTangent>
                <outTangent>1.99777794</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.9996019</value>
                <inTangent>0.0262048841</inTangent>
                <outTangent>0.0262048841</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.94576627</value>
                <inTangent>-0.009904087</inTangent>
                <outTangent>-0.009904087</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.9797937</value>
                <inTangent>0.912045</inTangent>
                <outTangent>0.912045</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.8783237</value>
                <inTangent>-0.101470053</inTangent>
                <outTangent>-0.101470053</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.306661636</value>
                <inTangent>0.8477192</inTangent>
                <outTangent>0.8477192</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.5410575</value>
                <inTangent>0.145162731</inTangent>
                <outTangent>0.145162731</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.0163361747</value>
                <inTangent>0.101265222</inTangent>
                <outTangent>0.101265222</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.743588</value>
                <inTangent>-0.218338668</inTangent>
                <outTangent>-0.218338668</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.453013569</value>
                <inTangent>-1.19660151</inTangent>
                <outTangent>-1.19660151</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.951818645</value>
                <inTangent>-1.79280424</inTangent>
                <outTangent>-1.79280424</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.8409856</value>
                <inTangent>0.02402401</inTangent>
                <outTangent>0.02402401</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.9998666</value>
                <inTangent>0.08617377</inTangent>
                <outTangent>0.08617377</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.66863817</value>
                <inTangent>-0.0541814566</inTangent>
                <outTangent>-0.0541814566</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.891503632</value>
                <inTangent>1.5601418</inTangent>
                <outTangent>1.5601418</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.363563925</value>
                <inTangent>-0.153549582</inTangent>
                <outTangent>-0.153549582</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.5171135</value>
                <inTangent>0.011654526</inTangent>
                <outTangent>0.011654526</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.340254873</value>
                <inTangent>-0.03885719</inTangent>
                <outTangent>-0.03885719</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.5948279</value>
                <inTangent>0.155974731</inTangent>
                <outTangent>0.155974731</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.0283054411</value>
                <inTangent>0.5665225</inTangent>
                <outTangent>0.5665225</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.9315693</value>
                <inTangent>0.07565242</inTangent>
                <outTangent>0.07565242</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.855916858</value>
                <inTangent>-0.00438201427</inTangent>
                <outTangent>-0.00438201427</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.9403333</value>
                <inTangent>0.0260319114</inTangent>
                <outTangent>0.0260319114</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.803853035</value>
                <inTangent>0.9699663</inTangent>
                <outTangent>0.9699663</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.999599338</value>
                <inTangent>1.80345237</inTangent>
                <outTangent>1.80345237</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.3459592</value>
                <inTangent>0.154596269</inTangent>
                <outTangent>0.154596269</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.500555456</value>
                <inTangent>-0.007940546</inTangent>
                <outTangent>-0.007940546</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.3300781</value>
                <inTangent>-0.102563426</inTangent>
                <outTangent>-0.102563426</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.2954286</value>
                <inTangent>-0.126471862</inTangent>
                <outTangent>-0.126471862</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.07713438</value>
                <inTangent>-0.218294233</inTangent>
                <outTangent>-0.218294233</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9382496</value>
                <inTangent>-0.07254511</inTangent>
                <outTangent>-0.07254511</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.8657045</value>
                <inTangent>0.00285202265</inTangent>
                <outTangent>0.00285202265</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.943953633</value>
                <inTangent>0.0448301733</inTangent>
                <outTangent>0.0448301733</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.9553648</value>
                <inTangent>0.0265335441</inTangent>
                <outTangent>0.0265335441</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.9970207</value>
                <inTangent>0.0416558981</inTangent>
                <outTangent>0.0416558981</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.290746629</value>
                <inTangent>0.860737741</inTangent>
                <outTangent>0.860737741</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.5699911</value>
                <inTangent>0.390766144</inTangent>
                <outTangent>0.390766144</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.490785629</value>
                <inTangent>-0.07258263</inTangent>
                <outTangent>-0.07258263</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.424825847</value>
                <inTangent>0.0211905986</inTangent>
                <outTangent>0.0211905986</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.5331668</value>
                <inTangent>0.108340979</inTangent>
                <outTangent>0.108340979</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9568001</value>
                <inTangent>-1.778451</inTangent>
                <outTangent>-1.778451</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.8216509</value>
                <inTangent>-0.9140402</inTangent>
                <outTangent>-0.9140402</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.871280432</value>
                <inTangent>-0.0418120921</inTangent>
                <outTangent>-0.0418120921</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.9052751</value>
                <inTangent>0.0126351416</inTangent>
                <outTangent>0.0126351416</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.846010149</value>
                <inTangent>0.0592649579</inTangent>
                <outTangent>0.0592649579</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.0582208931</value>
                <inTangent>-0.0239411</inTangent>
                <outTangent>-0.0239411</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.08216199</value>
                <inTangent>-0.0587337</inTangent>
                <outTangent>-0.0587337</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.1756883</value>
                <inTangent>-0.0281287581</inTangent>
                <outTangent>-0.0281287581</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.138419509</value>
                <inTangent>-0.1453168</inTangent>
                <outTangent>-0.1453168</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.4663219</value>
                <inTangent>-0.327902377</inTangent>
                <outTangent>-0.327902377</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9983038</value>
                <inTangent>-1.99492276</inTangent>
                <outTangent>-1.99492276</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.996619</value>
                <inTangent>-0.99137485</inTangent>
                <outTangent>-0.99137485</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.9844459</value>
                <inTangent>0.00312265754</inTangent>
                <outTangent>0.00312265754</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.9903737</value>
                <inTangent>0.0499154031</inTangent>
                <outTangent>0.0499154031</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.8846151</value>
                <inTangent>0.105758548</inTangent>
                <outTangent>0.105758548</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1.06147075</value>
                <inTangent>-0.04374981</inTangent>
                <outTangent>-0.04374981</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1.01772094</value>
                <inTangent>0.1157285</inTangent>
                <outTangent>0.1157285</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1.29292774</value>
                <inTangent>0.9140512</inTangent>
                <outTangent>0.9140512</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>2.84582329</value>
                <inTangent>0.2563088</inTangent>
                <outTangent>0.2563088</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1.80554533</value>
                <inTangent>-1.040278</inTangent>
                <outTangent>-1.040278</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.452485561</value>
                <inTangent>-0.554166555</inTangent>
                <outTangent>-0.554166555</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.101680994</value>
                <inTangent>-0.617585659</inTangent>
                <outTangent>-0.617585659</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.7826857</value>
                <inTangent>0.33688572</inTangent>
                <outTangent>0.33688572</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.5720904</value>
                <inTangent>1.30690193</inTangent>
                <outTangent>1.30690193</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1.83111811</value>
                <inTangent>1.25902772</inTangent>
                <outTangent>1.25902772</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.6681943</value>
                <inTangent>1.26388884</inTangent>
                <outTangent>1.26388884</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1.93208313</value>
                <inTangent>0.884722233</inTangent>
                <outTangent>0.884722233</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>2.43763876</value>
                <inTangent>-0.0826388</inTangent>
                <outTangent>-0.0826388</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1.76680553</value>
                <inTangent>-0.235763848</inTangent>
                <outTangent>-0.235763848</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1.96611106</value>
                <inTangent>0.199305534</inTangent>
                <outTangent>0.199305534</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.684721947</value>
                <inTangent>-0.884722233</inTangent>
                <outTangent>-0.884722233</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.200000286</value>
                <inTangent>-0.0340278149</inTangent>
                <outTangent>-0.0340278149</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.6166663</value>
                <inTangent>0.831249833</inTangent>
                <outTangent>0.831249833</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1.46249938</value>
                <inTangent>0.08020818</inTangent>
                <outTangent>0.08020818</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.7770827</value>
                <inTangent>-0.6854167</inTangent>
                <outTangent>-0.6854167</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.7216666</value>
                <inTangent>-0.06319451</inTangent>
                <outTangent>-0.06319451</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.658472061</value>
                <inTangent>-0.0315972567</inTangent>
                <outTangent>-0.0315972567</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.658472061</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.658472061</value>
                <inTangent>-0.486111164</inTangent>
                <outTangent>-0.486111164</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.313750267</value>
                <inTangent>-0.9722223</inTangent>
                <outTangent>-0.9722223</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-2.59888887</value>
                <inTangent>-0.0145831108</inTangent>
                <outTangent>-0.0145831108</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-2.613472</value>
                <inTangent>-0.0072915554</inTangent>
                <outTangent>-0.0072915554</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-2.613472</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-2.613472</value>
                <inTangent>0.05347228</inTangent>
                <outTangent>0.05347228</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-2.50652742</value>
                <inTangent>0.106944561</inTangent>
                <outTangent>0.106944561</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.9780555</value>
                <inTangent>-0.0194444656</inTangent>
                <outTangent>-0.0194444656</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.997499943</value>
                <inTangent>-0.00236582756</inTangent>
                <outTangent>-0.00236582756</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.982787132</value>
                <inTangent>0.34277308</inTangent>
                <outTangent>0.34277308</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.311953783</value>
                <inTangent>1.19219315</inTangent>
                <outTangent>1.19219315</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1.40159917</value>
                <inTangent>1.713553</inTangent>
                <outTangent>1.713553</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-2.53569412</value>
                <inTangent>0.0291666985</inTangent>
                <outTangent>0.0291666985</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-2.50652742</value>
                <inTangent>0.0241636038</inTangent>
                <outTangent>0.0241636038</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-2.487367</value>
                <inTangent>0.2890941</inTangent>
                <outTangent>0.2890941</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.92833924</value>
                <inTangent>-0.04260373</inTangent>
                <outTangent>-0.04260373</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-2.57257438</value>
                <inTangent>-0.644235134</inTangent>
                <outTangent>-0.644235134</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.0588889122</value>
                <inTangent>0.04374981</inTangent>
                <outTangent>0.04374981</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.0151391029</value>
                <inTangent>0.250347018</inTangent>
                <outTangent>0.250347018</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.441805124</value>
                <inTangent>0.2162255</inTangent>
                <outTangent>0.2162255</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.4173119</value>
                <inTangent>0.028003931</inTangent>
                <outTangent>0.028003931</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.497813</value>
                <inTangent>0.08050108</inTangent>
                <outTangent>0.08050108</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.5859723</value>
                <inTangent>-0.2819445</inTangent>
                <outTangent>-0.2819445</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.8679168</value>
                <inTangent>-0.02673614</inTangent>
                <outTangent>-0.02673614</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.6394446</value>
                <inTangent>0.1495105</inTangent>
                <outTangent>0.1495105</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.5688958</value>
                <inTangent>-0.0619516075</inTangent>
                <outTangent>-0.0619516075</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.7633478</value>
                <inTangent>-0.194451988</inTangent>
                <outTangent>-0.194451988</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.03611629</value>
                <inTangent>0.202637285</inTangent>
                <outTangent>0.202637285</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.238753572</value>
                <inTangent>0.0577133521</inTangent>
                <outTangent>0.0577133521</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.151542991</value>
                <inTangent>-0.0184676275</inTangent>
                <outTangent>-0.0184676275</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.201818317</value>
                <inTangent>0.02174782</inTangent>
                <outTangent>0.02174782</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.195038632</value>
                <inTangent>-0.00677968562</inTangent>
                <outTangent>-0.00677968562</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.9993476</value>
                <inTangent>0.0282674432</inTangent>
                <outTangent>0.0282674432</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9710802</value>
                <inTangent>0.00544846058</inTangent>
                <outTangent>0.00544846058</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.9884507</value>
                <inTangent>-0.00417140126</inTangent>
                <outTangent>-0.00417140126</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.979423</value>
                <inTangent>0.00382757187</inTangent>
                <outTangent>0.00382757187</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.980795562</value>
                <inTangent>-0.00137257576</inTangent>
                <outTangent>-0.00137257576</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.0307749938</value>
                <inTangent>0.005375756</inTangent>
                <outTangent>0.005375756</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.03615075</value>
                <inTangent>0.045184724</inTangent>
                <outTangent>0.045184724</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.121144444</value>
                <inTangent>0.0576465651</inTangent>
                <outTangent>0.0576465651</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.151443884</value>
                <inTangent>0.01514972</inTangent>
                <outTangent>0.01514972</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.151443884</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.385964781</value>
                <inTangent>-0.0447971821</inTangent>
                <outTangent>-0.0447971821</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.3411676</value>
                <inTangent>-0.00267215073</inTangent>
                <outTangent>-0.00267215073</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.38062048</value>
                <inTangent>0.0108676851</inTangent>
                <outTangent>0.0108676851</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.362902969</value>
                <inTangent>-0.008858755</inTangent>
                <outTangent>-0.008858755</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.362902969</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
      </item>
    </curves>
    <name>Punch1</name>
    <attacking>LeftArm</attacking>
    <defending>RightArm</defending>
    <length>0.8</length>
  </punch1>
  <punch2>
    <curves>
      <item>
        <key>
          <string>Armaturem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.497813</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.138993025</value>
                <inTangent>-0.47638917</inTangent>
                <outTangent>-0.47638917</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.454965353</value>
                <inTangent>-0.315972328</inTangent>
                <outTangent>-0.315972328</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.7633478</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.875153363</value>
                <inTangent>-0.0461805463</inTangent>
                <outTangent>-0.0461805463</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.8557089</value>
                <inTangent>0.0194444656</inTangent>
                <outTangent>0.0194444656</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9824582</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.8939885</value>
                <inTangent>0.008604169</inTangent>
                <outTangent>0.008604169</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.9996665</value>
                <inTangent>0.105678022</inTangent>
                <outTangent>0.105678022</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.18648313</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.448089957</value>
                <inTangent>-0.080330044</inTangent>
                <outTangent>-0.080330044</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.0258230623</value>
                <inTangent>0.473913</inTangent>
                <outTangent>0.473913</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.008715317</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.5715195</value>
                <inTangent>0.000761598349</inTangent>
                <outTangent>0.000761598349</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.0102384994</value>
                <inTangent>-0.561280966</inTangent>
                <outTangent>-0.561280966</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.999962032</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.8205885</value>
                <inTangent>7.212162E-06</inTangent>
                <outTangent>7.212162E-06</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.9999476</value>
                <inTangent>-0.179359078</inTangent>
                <outTangent>-0.179359078</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.287236243</value>
                <inTangent>0.178183764</inTangent>
                <outTangent>0.178183764</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.5512458</value>
                <inTangent>-0.6427693</inTangent>
                <outTangent>-0.6427693</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.998302341</value>
                <inTangent>-1.54954815</inTangent>
                <outTangent>-1.54954815</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.9578598</value>
                <inTangent>0.07276988</inTangent>
                <outTangent>0.07276988</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.834342957</value>
                <inTangent>0.4498073</inTangent>
                <outTangent>0.4498073</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.0582452677</value>
                <inTangent>0.7760977</inTangent>
                <outTangent>0.7760977</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.405982941</value>
                <inTangent>-0.4222873</inTangent>
                <outTangent>-0.4222873</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.008472145</value>
                <inTangent>-0.5232937</inTangent>
                <outTangent>-0.5232937</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.640604436</value>
                <inTangent>-0.6490766</inTangent>
                <outTangent>-0.6490766</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.913880646</value>
                <inTangent>0.0859864354</inTangent>
                <outTangent>0.0859864354</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.9999641</value>
                <inTangent>-0.840875864</inTangent>
                <outTangent>-0.840875864</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.7678711</value>
                <inTangent>-1.76783514</inTangent>
                <outTangent>-1.76783514</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.9975802</value>
                <inTangent>0.230623126</inTangent>
                <outTangent>0.230623126</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.752390265</value>
                <inTangent>0.2160801</inTangent>
                <outTangent>0.2160801</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.56542</value>
                <inTangent>0.1869703</inTangent>
                <outTangent>0.1869703</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.06952664</value>
                <inTangent>0.5721719</inTangent>
                <outTangent>0.5721719</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.6587177</value>
                <inTangent>0.3776383</inTangent>
                <outTangent>0.3776383</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.824803233</value>
                <inTangent>0.166085541</inTangent>
                <outTangent>0.166085541</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.7696187</value>
                <inTangent>0.06593466</inTangent>
                <outTangent>0.06593466</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.9180822</value>
                <inTangent>0.08067894</inTangent>
                <outTangent>0.08067894</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.93097657</value>
                <inTangent>0.012894392</inTangent>
                <outTangent>0.012894392</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.63850385</value>
                <inTangent>0.08909446</inTangent>
                <outTangent>0.08909446</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.39639008</value>
                <inTangent>0.136712417</inTangent>
                <outTangent>0.136712417</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.365079015</value>
                <inTangent>0.031311065</inTangent>
                <outTangent>0.031311065</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.8288278</value>
                <inTangent>-0.266353846</inTangent>
                <outTangent>-0.266353846</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.563112855</value>
                <inTangent>0.0151262581</inTangent>
                <outTangent>0.0151262581</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.8590803</value>
                <inTangent>0.29596746</inTangent>
                <outTangent>0.29596746</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.5595038</value>
                <inTangent>0.267311215</inTangent>
                <outTangent>0.267311215</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.82638</value>
                <inTangent>-0.0238314867</inTangent>
                <outTangent>-0.0238314867</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.5118408</value>
                <inTangent>-0.3145392</inTangent>
                <outTangent>-0.3145392</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.9952476</value>
                <inTangent>0.1632297</inTangent>
                <outTangent>0.1632297</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.759146333</value>
                <inTangent>0.00755807757</inTangent>
                <outTangent>0.00755807757</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.980131447</value>
                <inTangent>-0.220985115</inTangent>
                <outTangent>-0.220985115</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.09737645</value>
                <inTangent>0.457372248</inTangent>
                <outTangent>0.457372248</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.650920033</value>
                <inTangent>0.0504862666</inTangent>
                <outTangent>0.0504862666</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.198349</value>
                <inTangent>-0.452571034</inTangent>
                <outTangent>-0.452571034</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.49011612E-07</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>5.96046448E-08</value>
                <inTangent>1.1222437E-07</inTangent>
                <outTangent>1.1222437E-07</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>7.543713E-08</value>
                <inTangent>1.58324838E-08</inTangent>
                <outTangent>1.58324838E-08</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.478066444</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.4584758</value>
                <inTangent>-0.1558642</inTangent>
                <outTangent>-0.1558642</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.166338041</value>
                <inTangent>-0.292137742</inTangent>
                <outTangent>-0.292137742</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.8783237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.8887069</value>
                <inTangent>0.0538725555</inTangent>
                <outTangent>0.0538725555</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.9860688</value>
                <inTangent>0.09736186</inTangent>
                <outTangent>0.09736186</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.453013569</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.9052132</value>
                <inTangent>0.5188789</inTangent>
                <outTangent>0.5188789</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.584744155</value>
                <inTangent>-0.320469022</inTangent>
                <outTangent>-0.320469022</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.891503632</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.424957871</value>
                <inTangent>-0.851360738</inTangent>
                <outTangent>-0.851360738</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.8112178</value>
                <inTangent>-0.3862599</inTangent>
                <outTangent>-0.3862599</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.0283054411</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.6096363</value>
                <inTangent>-0.206678092</inTangent>
                <outTangent>-0.206678092</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.4416616</value>
                <inTangent>0.16797471</inTangent>
                <outTangent>0.16797471</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.999599338</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.792681336</value>
                <inTangent>-0.948390543</inTangent>
                <outTangent>-0.948390543</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.897181749</value>
                <inTangent>-0.104500413</inTangent>
                <outTangent>-0.104500413</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.07713438</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.3199389</value>
                <inTangent>0.204486817</inTangent>
                <outTangent>0.204486817</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.486108</value>
                <inTangent>0.1661691</inTangent>
                <outTangent>0.1661691</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9970207</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.9474382</value>
                <inTangent>-0.06156096</inTangent>
                <outTangent>-0.06156096</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.8738988</value>
                <inTangent>-0.0735393763</inTangent>
                <outTangent>-0.0735393763</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.5331668</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.650709152</value>
                <inTangent>-0.4914473</inTangent>
                <outTangent>-0.4914473</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.449727744</value>
                <inTangent>-1.10043693</inTangent>
                <outTangent>-1.10043693</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.846010149</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.7593271</value>
                <inTangent>0.8695879</inTangent>
                <outTangent>0.8695879</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.893165648</value>
                <inTangent>1.65249276</inTangent>
                <outTangent>1.65249276</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.4663219</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.373568416</value>
                <inTangent>0.212581724</inTangent>
                <outTangent>0.212581724</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.0411584377</value>
                <inTangent>0.332409978</inTangent>
                <outTangent>0.332409978</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.8846151</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.927602649</value>
                <inTangent>0.9418839</inTangent>
                <outTangent>0.9418839</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.99915266</value>
                <inTangent>1.92675531</inTangent>
                <outTangent>1.92675531</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1.80554533</value>
                <inTangent>1.19494009</inTangent>
                <outTangent>1.19494009</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1.83724427</value>
                <inTangent>-0.6759529</inTangent>
                <outTangent>-0.6759529</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.4536395</value>
                <inTangent>-1.38360476</inTangent>
                <outTangent>-1.38360476</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1.83111811</value>
                <inTangent>-1.28505337</inTangent>
                <outTangent>-1.28505337</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.162972689</value>
                <inTangent>-1.57331133</inTangent>
                <outTangent>-1.57331133</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.31550443</value>
                <inTangent>-1.47847712</inTangent>
                <outTangent>-1.47847712</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1.96611106</value>
                <inTangent>0.73888886</inTangent>
                <outTangent>0.73888886</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1.40182376</value>
                <inTangent>-0.617669046</inTangent>
                <outTangent>-0.617669046</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.730773</value>
                <inTangent>-0.6710508</inTangent>
                <outTangent>-0.6710508</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.7770827</value>
                <inTangent>-0.899305463</inTangent>
                <outTangent>-0.899305463</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.7773312</value>
                <inTangent>-0.161030293</inTangent>
                <outTangent>-0.161030293</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.4550221</value>
                <inTangent>1.23235321</inTangent>
                <outTangent>1.23235321</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.313750267</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.342916965</value>
                <inTangent>0.401041627</inTangent>
                <outTangent>0.401041627</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.488333</value>
                <inTangent>0.831249952</inTangent>
                <outTangent>0.831249952</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-2.50652742</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-2.60861063</value>
                <inTangent>-0.06805551</inTangent>
                <outTangent>-0.06805551</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-2.64263844</value>
                <inTangent>-0.0340278149</inTangent>
                <outTangent>-0.0340278149</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1.40159917</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.455345154</value>
                <inTangent>-1.22986114</inTangent>
                <outTangent>-1.22986114</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.05812311</value>
                <inTangent>-0.602777958</inTangent>
                <outTangent>-0.602777958</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-2.57257438</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.85312974</value>
                <inTangent>-0.0194443464</inTangent>
                <outTangent>-0.0194443464</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-2.611463</value>
                <inTangent>-0.7583333</inTangent>
                <outTangent>-0.7583333</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.497813</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.138993025</value>
                <inTangent>-0.47638917</inTangent>
                <outTangent>-0.47638917</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.454965353</value>
                <inTangent>-0.315972328</inTangent>
                <outTangent>-0.315972328</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.7633478</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.875153363</value>
                <inTangent>-0.0461805463</inTangent>
                <outTangent>-0.0461805463</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.8557089</value>
                <inTangent>0.0194444656</inTangent>
                <outTangent>0.0194444656</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.195038632</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.1432343</value>
                <inTangent>-0.07949096</inTangent>
                <outTangent>-0.07949096</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.03605672</value>
                <inTangent>-0.107177578</inTangent>
                <outTangent>-0.107177578</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.980795562</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9896888</value>
                <inTangent>-0.009277105</inTangent>
                <outTangent>-0.009277105</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.9993498</value>
                <inTangent>-0.009660959</inTangent>
                <outTangent>-0.009660959</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.151443884</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.0573052242</value>
                <inTangent>-0.06102307</inTangent>
                <outTangent>-0.06102307</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.0293977465</value>
                <inTangent>0.08670297</inTangent>
                <outTangent>0.08670297</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.362902969</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.3140729</value>
                <inTangent>0.0177241266</inTangent>
                <outTangent>0.0177241266</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.398351222</value>
                <inTangent>0.0842783153</inTangent>
                <outTangent>0.0842783153</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
      </item>
    </curves>
    <name>Punch2</name>
    <attacking>LeftArm</attacking>
    <defending>RightArm</defending>
    <length>0.5</length>
  </punch2>
  <punch3>
    <curves>
      <item>
        <key>
          <string>Armaturem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.454965353</value>
                <inTangent>0.08178902</inTangent>
                <outTangent>0.08178902</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.373176336</value>
                <inTangent>0.04089451</inTangent>
                <outTangent>0.04089451</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.373176336</value>
                <inTangent>-0.0364583731</inTangent>
                <outTangent>-0.0364583731</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.446093082</value>
                <inTangent>-0.0471619368</inTangent>
                <outTangent>-0.0471619368</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.4675002</value>
                <inTangent>0.132699251</inTangent>
                <outTangent>0.132699251</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.18069458</value>
                <inTangent>0.5134493</inTangent>
                <outTangent>0.5134493</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.5593984</value>
                <inTangent>0.3068521</inTangent>
                <outTangent>0.3068521</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.433009624</value>
                <inTangent>-0.126388788</inTangent>
                <outTangent>-0.126388788</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.8557089</value>
                <inTangent>0.3255381</inTangent>
                <outTangent>0.3255381</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.5301708</value>
                <inTangent>0.131171823</inTangent>
                <outTangent>0.131171823</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.593365252</value>
                <inTangent>-0.226041675</inTangent>
                <outTangent>-0.226041675</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.982254148</value>
                <inTangent>-0.39136222</inTangent>
                <outTangent>-0.39136222</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.37608969</value>
                <inTangent>-0.284417748</inTangent>
                <outTangent>-0.284417748</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.55108964</value>
                <inTangent>0.2965269</inTangent>
                <outTangent>0.2965269</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.7830359</value>
                <inTangent>0.272221327</inTangent>
                <outTangent>0.272221327</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.006647</value>
                <inTangent>-0.223611116</inTangent>
                <outTangent>-0.223611116</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9996665</value>
                <inTangent>0.0001296401</inTangent>
                <outTangent>0.0001296401</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.999796152</value>
                <inTangent>8.225441E-05</inTangent>
                <outTangent>8.225441E-05</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.999831</value>
                <inTangent>-0.0211768746</inTangent>
                <outTangent>-0.0211768746</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.9574424</value>
                <inTangent>-0.02815625</inTangent>
                <outTangent>-0.02815625</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.9435185</value>
                <inTangent>0.0170835555</inTangent>
                <outTangent>0.0170835555</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.9916095</value>
                <inTangent>0.00731220841</inTangent>
                <outTangent>0.00731220841</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.958142936</value>
                <inTangent>-0.00144845247</inTangent>
                <outTangent>-0.00144845247</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.9887126</value>
                <inTangent>0.0305696726</inTangent>
                <outTangent>0.0305696726</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.0258230623</value>
                <inTangent>-0.005631497</inTangent>
                <outTangent>-0.005631497</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.0201915652</value>
                <inTangent>-0.00371859036</inTangent>
                <outTangent>-0.00371859036</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.0183858816</value>
                <inTangent>-0.154407978</inTangent>
                <outTangent>-0.154407978</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.288624376</value>
                <inTangent>-0.174852863</inTangent>
                <outTangent>-0.174852863</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.331319839</value>
                <inTangent>0.07967749</inTangent>
                <outTangent>0.07967749</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.129269391</value>
                <inTangent>0.3088051</inTangent>
                <outTangent>0.3088051</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.2862904</value>
                <inTangent>-0.010277614</inTangent>
                <outTangent>-0.010277614</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.149824634</value>
                <inTangent>-0.436115026</inTangent>
                <outTangent>-0.436115026</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.0102384994</value>
                <inTangent>0.00227591675</inTangent>
                <outTangent>0.00227591675</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.0125144161</value>
                <inTangent>0.00262971455</inTangent>
                <outTangent>0.00262971455</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.0154979285</value>
                <inTangent>0.234378085</inTangent>
                <outTangent>0.234378085</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.481270581</value>
                <inTangent>0.193062335</inTangent>
                <outTangent>0.193062335</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.4016226</value>
                <inTangent>-0.11837735</inTangent>
                <outTangent>-0.11837735</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.244515881</value>
                <inTangent>-0.194029748</inTangent>
                <outTangent>-0.194029748</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.0135630965</value>
                <inTangent>-0.0105524585</inTangent>
                <outTangent>-0.0105524585</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.223410964</value>
                <inTangent>0.209847867</inTangent>
                <outTangent>0.209847867</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.9999476</value>
                <inTangent>2.58684158E-05</inTangent>
                <outTangent>2.58684158E-05</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.999921739</value>
                <inTangent>3.38256359E-05</inTangent>
                <outTangent>3.38256359E-05</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.999879956</value>
                <inTangent>0.0616748035</inTangent>
                <outTangent>0.0616748035</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.876572132</value>
                <inTangent>0.04203731</inTangent>
                <outTangent>0.04203731</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.91580534</value>
                <inTangent>-0.0465365946</inTangent>
                <outTangent>-0.0465365946</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.9696453</value>
                <inTangent>-0.0420513749</inTangent>
                <outTangent>-0.0420513749</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.9999081</value>
                <inTangent>-0.0025395155</inTangent>
                <outTangent>-0.0025395155</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.974724352</value>
                <inTangent>0.0251837373</inTangent>
                <outTangent>0.0251837373</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.998302341</value>
                <inTangent>0.0441994667</inTangent>
                <outTangent>0.0441994667</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9541029</value>
                <inTangent>0.0053794086</inTangent>
                <outTangent>0.0053794086</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.9875435</value>
                <inTangent>-0.0158137679</inTangent>
                <outTangent>-0.0158137679</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.9857304</value>
                <inTangent>0.968050241</inTangent>
                <outTangent>0.968050241</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.9485569</value>
                <inTangent>0.9542073</inTangent>
                <outTangent>0.9542073</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.922684252</value>
                <inTangent>-0.232876584</inTangent>
                <outTangent>-0.232876584</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.482803732</value>
                <inTangent>-0.10297069</inTangent>
                <outTangent>-0.10297069</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.7167429</value>
                <inTangent>0.233939141</inTangent>
                <outTangent>0.233939141</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.0582452677</value>
                <inTangent>-0.241233885</inTangent>
                <outTangent>-0.241233885</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.299479157</value>
                <inTangent>-0.0495503321</inTangent>
                <outTangent>-0.0495503321</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.157345936</value>
                <inTangent>0.233905464</inTangent>
                <outTangent>0.233905464</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.168331772</value>
                <inTangent>-0.07963051</inTangent>
                <outTangent>-0.07963051</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.316606939</value>
                <inTangent>-0.2769441</inTangent>
                <outTangent>-0.2769441</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.385556459</value>
                <inTangent>-0.2795608</inTangent>
                <outTangent>-0.2795608</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.875728548</value>
                <inTangent>-0.155890584</inTangent>
                <outTangent>-0.155890584</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.6973376</value>
                <inTangent>0.17839092</inTangent>
                <outTangent>0.17839092</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.640604436</value>
                <inTangent>-0.206190825</inTangent>
                <outTangent>-0.206190825</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.846795261</value>
                <inTangent>-0.100547791</inTangent>
                <outTangent>-0.100547791</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.8417</value>
                <inTangent>-0.00700798631</inTangent>
                <outTangent>-0.00700798631</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.860811234</value>
                <inTangent>0.8084512</inTangent>
                <outTangent>0.8084512</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.7752023</value>
                <inTangent>0.7895463</inTangent>
                <outTangent>0.7895463</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.7182814</value>
                <inTangent>-0.182533488</inTangent>
                <outTangent>-0.182533488</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.4101353</value>
                <inTangent>-0.137990713</inTangent>
                <outTangent>-0.137990713</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.442299962</value>
                <inTangent>0.0321646631</inTangent>
                <outTangent>0.0321646631</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.7678711</value>
                <inTangent>0.235952079</inTangent>
                <outTangent>0.235952079</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.531919</value>
                <inTangent>0.11396274</inTangent>
                <outTangent>0.11396274</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.5399456</value>
                <inTangent>0.0114973485</inTangent>
                <outTangent>0.0114973485</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.5089243</value>
                <inTangent>0.5858294</inTangent>
                <outTangent>0.5858294</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.631713152</value>
                <inTangent>0.6023385</inTangent>
                <outTangent>0.6023385</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.69575274</value>
                <inTangent>0.140155762</inTangent>
                <outTangent>0.140155762</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.9120247</value>
                <inTangent>0.100557208</inTangent>
                <outTangent>0.100557208</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.896867156</value>
                <inTangent>-0.0151575208</inTangent>
                <outTangent>-0.0151575208</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.56542</value>
                <inTangent>-0.0579748154</inTangent>
                <outTangent>-0.0579748154</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.6233948</value>
                <inTangent>-0.07892847</inTangent>
                <outTangent>-0.07892847</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.7232769</value>
                <inTangent>-0.108140171</inTangent>
                <outTangent>-0.108140171</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.8396751</value>
                <inTangent>-0.0913326442</inTangent>
                <outTangent>-0.0913326442</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.9059422</value>
                <inTangent>-0.0210828185</inTangent>
                <outTangent>-0.0210828185</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.881840765</value>
                <inTangent>-0.0103966594</inTangent>
                <outTangent>-0.0103966594</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.9267355</value>
                <inTangent>-0.01646018</inTangent>
                <outTangent>-0.01646018</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.9147611</value>
                <inTangent>0.0119743943</inTangent>
                <outTangent>0.0119743943</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.824803233</value>
                <inTangent>-0.0428959131</inTangent>
                <outTangent>-0.0428959131</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.7819073</value>
                <inTangent>-0.06712255</inTangent>
                <outTangent>-0.06712255</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.690558136</value>
                <inTangent>-0.119409144</inTangent>
                <outTangent>-0.119409144</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.543089032</value>
                <inTangent>-0.1335783</inTangent>
                <outTangent>-0.1335783</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.423401535</value>
                <inTangent>-0.03577079</inTangent>
                <outTangent>-0.03577079</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.471547455</value>
                <inTangent>-0.0238435417</inTangent>
                <outTangent>-0.0238435417</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.375714451</value>
                <inTangent>-0.03377606</inTangent>
                <outTangent>-0.03377606</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.403995335</value>
                <inTangent>0.028280884</inTangent>
                <outTangent>0.028280884</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.93097657</value>
                <inTangent>-0.3050453</inTangent>
                <outTangent>-0.3050453</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.655173242</value>
                <inTangent>-0.173284024</inTangent>
                <outTangent>-0.173284024</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.5844085</value>
                <inTangent>-0.2406713</inTangent>
                <outTangent>-0.2406713</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.173830643</value>
                <inTangent>-0.231765121</inTangent>
                <outTangent>-0.231765121</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.120878294</value>
                <inTangent>0.0504187271</inTangent>
                <outTangent>0.0504187271</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.2746681</value>
                <inTangent>0.398155451</inTangent>
                <outTangent>0.398155451</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.9171892</value>
                <inTangent>0.3095188</inTangent>
                <outTangent>0.3095188</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.8937057</value>
                <inTangent>-0.0234834552</inTangent>
                <outTangent>-0.0234834552</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.365079015</value>
                <inTangent>-0.414799243</inTangent>
                <outTangent>-0.414799243</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.7554786</value>
                <inTangent>-0.2231903</inTangent>
                <outTangent>-0.2231903</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.8114596</value>
                <inTangent>-0.114648461</inTangent>
                <outTangent>-0.114648461</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.984775543</value>
                <inTangent>-0.09060386</inTangent>
                <outTangent>-0.09060386</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.9926673</value>
                <inTangent>0.011618197</inTangent>
                <outTangent>0.011618197</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.961539149</value>
                <inTangent>0.2971077</inTangent>
                <outTangent>0.2971077</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.398451924</value>
                <inTangent>0.256442726</inTangent>
                <outTangent>0.256442726</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.4486537</value>
                <inTangent>-0.0502017736</inTangent>
                <outTangent>-0.0502017736</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.8590803</value>
                <inTangent>-1.56643319</inTangent>
                <outTangent>-1.56643319</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.4543178</value>
                <inTangent>-0.0297147036</inTangent>
                <outTangent>-0.0297147036</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.7996509</value>
                <inTangent>-0.199812472</inTangent>
                <outTangent>-0.199812472</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.853942752</value>
                <inTangent>-0.80056715</inTangent>
                <outTangent>-0.80056715</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.8014834</value>
                <inTangent>0.07546213</inTangent>
                <outTangent>0.07546213</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.7030185</value>
                <inTangent>0.833389044</inTangent>
                <outTangent>0.833389044</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.8652947</value>
                <inTangent>0.757049561</inTangent>
                <outTangent>0.757049561</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.811080635</value>
                <inTangent>-0.05421406</inTangent>
                <outTangent>-0.05421406</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.5118408</value>
                <inTangent>0.195019841</inTangent>
                <outTangent>0.195019841</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.8908397</value>
                <inTangent>-0.556153059</inTangent>
                <outTangent>-0.556153059</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.6004653</value>
                <inTangent>-0.185236275</inTangent>
                <outTangent>-0.185236275</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.5203671</value>
                <inTangent>0.599241257</inTangent>
                <outTangent>0.599241257</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.5980171</value>
                <inTangent>0.09540227</inTangent>
                <outTangent>0.09540227</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.7111716</value>
                <inTangent>-0.0483768</inTangent>
                <outTangent>-0.0483768</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.5012635</value>
                <inTangent>-0.06311858</inTangent>
                <outTangent>-0.06311858</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.5849345</value>
                <inTangent>0.0836709738</inTangent>
                <outTangent>0.0836709738</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.980131447</value>
                <inTangent>0.9041873</inTangent>
                <outTangent>0.9041873</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.344841957</value>
                <inTangent>0.483089149</inTangent>
                <outTangent>0.483089149</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.0139531475</value>
                <inTangent>0.0911587253</inTangent>
                <outTangent>0.0911587253</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.1625245</value>
                <inTangent>-0.207920313</inTangent>
                <outTangent>-0.207920313</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.429793775</value>
                <inTangent>-0.111386687</inTangent>
                <outTangent>-0.111386687</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.3852979</value>
                <inTangent>-0.241821438</inTangent>
                <outTangent>-0.241821438</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.913436651</value>
                <inTangent>-0.293670774</inTangent>
                <outTangent>-0.293670774</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.972639441</value>
                <inTangent>-0.05920279</inTangent>
                <outTangent>-0.05920279</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.198349</value>
                <inTangent>0.7987631</inTangent>
                <outTangent>0.7987631</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.938660741</value>
                <inTangent>-0.5991258</inTangent>
                <outTangent>-0.5991258</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.999902666</value>
                <inTangent>0.0240218639</inTangent>
                <outTangent>0.0240218639</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.986704469</value>
                <inTangent>0.9514149</inTangent>
                <outTangent>0.9514149</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.90292716</value>
                <inTangent>-0.0319561064</inTangent>
                <outTangent>-0.0319561064</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.922792256</value>
                <inTangent>-0.247973144</inTangent>
                <outTangent>-0.247973144</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.406980872</value>
                <inTangent>-0.345236</inTangent>
                <outTangent>-0.345236</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.232320249</value>
                <inTangent>-0.174660623</inTangent>
                <outTangent>-0.174660623</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>7.543713E-08</value>
                <inTangent>-9.313297E-10</inTangent>
                <outTangent>-9.313297E-10</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>7.45058E-08</value>
                <inTangent>-1.19674937E-07</inTangent>
                <outTangent>-1.19674937E-07</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.63912759E-07</value>
                <inTangent>7.450595E-09</inTangent>
                <outTangent>7.450595E-09</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>8.940697E-08</value>
                <inTangent>9.685755E-08</inTangent>
                <outTangent>9.685755E-08</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>2.98023188E-08</value>
                <inTangent>-7.450582E-09</inTangent>
                <outTangent>-7.450582E-09</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>7.45058E-08</value>
                <inTangent>1.33644775E-07</inTangent>
                <outTangent>1.33644775E-07</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>2.97091873E-07</value>
                <inTangent>-1.2665987E-07</inTangent>
                <outTangent>-1.2665987E-07</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.78813934E-07</value>
                <inTangent>-4.759058E-07</inTangent>
                <outTangent>-4.759058E-07</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.166338041</value>
                <inTangent>-0.102208942</inTangent>
                <outTangent>-0.102208942</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.0641291</value>
                <inTangent>-0.0776589662</inTangent>
                <outTangent>-0.0776589662</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.0110201128</value>
                <inTangent>0.0608366057</inTangent>
                <outTangent>0.0608366057</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.185802311</value>
                <inTangent>0.1321786</inTangent>
                <outTangent>0.1321786</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.2753773</value>
                <inTangent>-0.0195601881</inTangent>
                <outTangent>-0.0195601881</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.146681935</value>
                <inTangent>0.0560081452</inTangent>
                <outTangent>0.0560081452</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.3873936</value>
                <inTangent>0.205734685</inTangent>
                <outTangent>0.205734685</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.5581513</value>
                <inTangent>0.170757711</inTangent>
                <outTangent>0.170757711</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9860688</value>
                <inTangent>-1.98401046</inTangent>
                <outTangent>-1.98401046</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9979416</value>
                <inTangent>-0.9930041</inTangent>
                <outTangent>-0.9930041</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.9999393</value>
                <inTangent>0.9902644</inTangent>
                <outTangent>0.9902644</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.982587159</value>
                <inTangent>0.9806378</inTangent>
                <outTangent>0.9806378</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.961336255</value>
                <inTangent>0.00329828262</inTangent>
                <outTangent>0.00329828262</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.9891837</value>
                <inTangent>-0.941625357</inTangent>
                <outTangent>-0.941625357</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.9219144</value>
                <inTangent>-0.0797222853</inTangent>
                <outTangent>-0.0797222853</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.829739153</value>
                <inTangent>1.75165355</inTangent>
                <outTangent>1.75165355</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.584744155</value>
                <inTangent>-0.766557157</inTangent>
                <outTangent>-0.766557157</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.181813</value>
                <inTangent>-0.43571198</inTangent>
                <outTangent>-0.43571198</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.286679775</value>
                <inTangent>0.406413436</inTangent>
                <outTangent>0.406413436</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.6310139</value>
                <inTangent>0.5418737</inTangent>
                <outTangent>0.5418737</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.797067642</value>
                <inTangent>0.08043325</inTangent>
                <outTangent>0.08043325</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.791880369</value>
                <inTangent>-0.5782469</inTangent>
                <outTangent>-0.5782469</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.359426171</value>
                <inTangent>-0.671093464</inTangent>
                <outTangent>-0.671093464</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.5503066</value>
                <inTangent>-0.190880448</inTangent>
                <outTangent>-0.190880448</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.8112178</value>
                <inTangent>1.7945509</inTangent>
                <outTangent>1.7945509</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.9833332</value>
                <inTangent>0.8846221</inTangent>
                <outTangent>0.8846221</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.9580265</value>
                <inTangent>-0.879552364</inTangent>
                <outTangent>-0.879552364</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.7757716</value>
                <inTangent>-0.7809583</inTangent>
                <outTangent>-0.7809583</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.603890061</value>
                <inTangent>0.0825476646</inTangent>
                <outTangent>0.0825476646</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.6106763</value>
                <inTangent>0.7685318</inTangent>
                <outTangent>0.7685318</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.933173537</value>
                <inTangent>0.7228195</inTangent>
                <outTangent>0.7228195</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.8349627</value>
                <inTangent>-0.09821081</inTangent>
                <outTangent>-0.09821081</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.4416616</value>
                <inTangent>0.197162569</inTangent>
                <outTangent>0.197162569</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.244499028</value>
                <inTangent>0.07222085</inTangent>
                <outTangent>0.07222085</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.2972199</value>
                <inTangent>-0.115693465</inTangent>
                <outTangent>-0.115693465</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.475885957</value>
                <inTangent>-0.151366249</inTangent>
                <outTangent>-0.151366249</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.5999524</value>
                <inTangent>-0.108927026</inTangent>
                <outTangent>-0.108927026</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.69374</value>
                <inTangent>-0.0464566052</inTangent>
                <outTangent>-0.0464566052</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.6928656</value>
                <inTangent>0.342157722</inTangent>
                <outTangent>0.342157722</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.009424597</value>
                <inTangent>0.683441043</inTangent>
                <outTangent>0.683441043</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.897181749</value>
                <inTangent>-0.07246786</inTangent>
                <outTangent>-0.07246786</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9696496</value>
                <inTangent>-0.02881366</inTangent>
                <outTangent>-0.02881366</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.95480907</value>
                <inTangent>0.045071274</inTangent>
                <outTangent>0.045071274</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.879507065</value>
                <inTangent>0.07738665</inTangent>
                <outTangent>0.07738665</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.8000358</value>
                <inTangent>0.0796407461</inTangent>
                <outTangent>0.0796407461</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.7202256</value>
                <inTangent>0.03948453</inTangent>
                <outTangent>0.03948453</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.7210667</value>
                <inTangent>0.8600906</inTangent>
                <outTangent>0.8600906</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.9999556</value>
                <inTangent>1.72102237</inTangent>
                <outTangent>1.72102237</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.486108</value>
                <inTangent>-0.268776</inTangent>
                <outTangent>-0.268776</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.217332</value>
                <inTangent>-0.08456282</inTangent>
                <outTangent>-0.08456282</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.316982359</value>
                <inTangent>0.160724163</inTangent>
                <outTangent>0.160724163</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.538780332</value>
                <inTangent>0.1881619</inTangent>
                <outTangent>0.1881619</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.693306148</value>
                <inTangent>0.08994147</inTangent>
                <outTangent>0.08994147</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.7186633</value>
                <inTangent>-0.223131061</inTangent>
                <outTangent>-0.223131061</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.247044042</value>
                <inTangent>-0.175377518</inTangent>
                <outTangent>-0.175377518</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.367908239</value>
                <inTangent>0.1208642</inTangent>
                <outTangent>0.1208642</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.8738988</value>
                <inTangent>0.102198958</inTangent>
                <outTangent>0.102198958</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.976097763</value>
                <inTangent>0.037266314</inTangent>
                <outTangent>0.037266314</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.948431432</value>
                <inTangent>-0.06682575</inTangent>
                <outTangent>-0.06682575</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.842446268</value>
                <inTangent>-0.113894135</inTangent>
                <outTangent>-0.113894135</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.720643163</value>
                <inTangent>-0.0735439658</inTangent>
                <outTangent>-0.0735439658</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.695358336</value>
                <inTangent>0.124180555</inTangent>
                <outTangent>0.124180555</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.9690043</value>
                <inTangent>0.117251873</inTangent>
                <outTangent>0.117251873</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.9298621</value>
                <inTangent>-0.03914219</inTangent>
                <outTangent>-0.03914219</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.449727744</value>
                <inTangent>0.8051771</inTangent>
                <outTangent>0.8051771</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.355449319</value>
                <inTangent>0.07867551</inTangent>
                <outTangent>0.07867551</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.292376757</value>
                <inTangent>0.122802645</inTangent>
                <outTangent>0.122802645</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.6010546</value>
                <inTangent>0.529323936</inTangent>
                <outTangent>0.529323936</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.766271055</value>
                <inTangent>0.1307759</inTangent>
                <outTangent>0.1307759</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.8626064</value>
                <inTangent>-0.100977689</inTangent>
                <outTangent>-0.100977689</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.5643157</value>
                <inTangent>-0.08189061</inTangent>
                <outTangent>-0.08189061</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.6988252</value>
                <inTangent>0.1345095</inTangent>
                <outTangent>0.1345095</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.893165648</value>
                <inTangent>-1.82786131</inTangent>
                <outTangent>-1.82786131</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9346956</value>
                <inTangent>0.0315687656</inTangent>
                <outTangent>0.0315687656</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.956303239</value>
                <inTangent>0.06774384</inTangent>
                <outTangent>0.06774384</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.7992079</value>
                <inTangent>-0.799410343</inTangent>
                <outTangent>-0.799410343</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.6425175</value>
                <inTangent>0.14666608</inTangent>
                <outTangent>0.14666608</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.505875766</value>
                <inTangent>-0.0915208161</inTangent>
                <outTangent>-0.0915208161</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.825559139</value>
                <inTangent>-0.104708374</inTangent>
                <outTangent>-0.104708374</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.7152925</value>
                <inTangent>0.110266626</inTangent>
                <outTangent>0.110266626</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.0411584377</value>
                <inTangent>-0.102655545</inTangent>
                <outTangent>-0.102655545</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.143813983</value>
                <inTangent>0.00766325</inTangent>
                <outTangent>0.00766325</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.02583194</value>
                <inTangent>0.0340276249</inTangent>
                <outTangent>0.0340276249</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.07575873</value>
                <inTangent>-0.0404573679</inTangent>
                <outTangent>-0.0404573679</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.106746674</value>
                <inTangent>-0.0802537352</inTangent>
                <outTangent>-0.0802537352</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.2362662</value>
                <inTangent>-0.118064076</inTangent>
                <outTangent>-0.118064076</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.342874825</value>
                <inTangent>-0.07519142</inTangent>
                <outTangent>-0.07519142</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.386649042</value>
                <inTangent>-0.0437742174</inTangent>
                <outTangent>-0.0437742174</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.99915266</value>
                <inTangent>-1.98875737</inTangent>
                <outTangent>-1.98875737</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9896047</value>
                <inTangent>0.0002568364</inTangent>
                <outTangent>0.0002568364</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.999666333</value>
                <inTangent>-0.003760755</inTangent>
                <outTangent>-0.003760755</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.997126162</value>
                <inTangent>-0.9969764</inTangent>
                <outTangent>-0.9969764</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.9942863</value>
                <inTangent>0.0127189159</inTangent>
                <outTangent>0.0127189159</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.97168833</value>
                <inTangent>0.0274525881</inTangent>
                <outTangent>0.0274525881</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.9393811</value>
                <inTangent>0.0247306824</inTangent>
                <outTangent>0.0247306824</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.922226965</value>
                <inTangent>0.0171541572</inTangent>
                <outTangent>0.0171541572</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.4536395</value>
                <inTangent>-0.313837528</inTangent>
                <outTangent>-0.313837528</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.139801979</value>
                <inTangent>-0.0183771849</inTangent>
                <outTangent>-0.0183771849</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.416885138</value>
                <inTangent>0.162847161</inTangent>
                <outTangent>0.162847161</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.4654963</value>
                <inTangent>0.123958349</inTangent>
                <outTangent>0.123958349</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.664801836</value>
                <inTangent>0.4083333</inTangent>
                <outTangent>0.4083333</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1.2821629</value>
                <inTangent>1.098761</inTangent>
                <outTangent>1.098761</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>2.86232376</value>
                <inTangent>0.4529531</inTangent>
                <outTangent>0.4529531</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>2.188069</value>
                <inTangent>-0.674254656</inTangent>
                <outTangent>-0.674254656</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.31550443</value>
                <inTangent>0.687891066</inTangent>
                <outTangent>0.687891066</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.627613366</value>
                <inTangent>0.394987166</inTangent>
                <outTangent>0.394987166</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.5255301</value>
                <inTangent>-0.0170139372</inTangent>
                <outTangent>-0.0170139372</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.66164124</value>
                <inTangent>-0.07048613</inTangent>
                <outTangent>-0.07048613</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.666502357</value>
                <inTangent>-0.0875000358</inTangent>
                <outTangent>-0.0875000358</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.8366413</value>
                <inTangent>0.7341668</inTangent>
                <outTangent>0.7341668</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.801831245</value>
                <inTangent>0.677565753</inTangent>
                <outTangent>0.677565753</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.5184902</value>
                <inTangent>-0.28334105</inTangent>
                <outTangent>-0.28334105</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.730773</value>
                <inTangent>0.140972376</inTangent>
                <outTangent>0.140972376</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1.4842453</value>
                <inTangent>-0.0486110449</inTangent>
                <outTangent>-0.0486110449</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.6335509</value>
                <inTangent>-0.418055534</inTangent>
                <outTangent>-0.418055534</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.648134232</value>
                <inTangent>0.0315971375</inTangent>
                <outTangent>0.0315971375</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.696745157</value>
                <inTangent>0.3937497</inTangent>
                <outTangent>0.3937497</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1.43563366</value>
                <inTangent>0.772916436</inTangent>
                <outTangent>0.772916436</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>2.242578</value>
                <inTangent>0.116666615</inTangent>
                <outTangent>0.116666615</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1.66896689</value>
                <inTangent>-0.57361114</inTangent>
                <outTangent>-0.57361114</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.4550221</value>
                <inTangent>-1.05486107</inTangent>
                <outTangent>-1.05486107</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.4053945</value>
                <inTangent>-0.515277743</inTangent>
                <outTangent>-0.515277743</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.5755334</value>
                <inTangent>-0.0753472149</inTangent>
                <outTangent>-0.0753472149</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.5560889</value>
                <inTangent>0.1190972</inTangent>
                <outTangent>0.1190972</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.337338984</value>
                <inTangent>-0.0996528</inTangent>
                <outTangent>-0.0996528</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.7553945</value>
                <inTangent>0.0899305642</inTangent>
                <outTangent>0.0899305642</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.157477856</value>
                <inTangent>0.5347222</inTangent>
                <outTangent>0.5347222</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.314049959</value>
                <inTangent>0.4715278</inTangent>
                <outTangent>0.4715278</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.488333</value>
                <inTangent>-0.7729173</inTangent>
                <outTangent>-0.7729173</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.284584284</value>
                <inTangent>-0.102083683</inTangent>
                <outTangent>-0.102083683</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.284165621</value>
                <inTangent>0.284374952</inTangent>
                <outTangent>0.284374952</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.284165621</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.284165621</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.284165621</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.284165621</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.284165621</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-2.64263844</value>
                <inTangent>-0.00736165047</inTangent>
                <outTangent>-0.00736165047</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-2.65</value>
                <inTangent>-0.00368082523</inTangent>
                <outTangent>-0.00368082523</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-2.65</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-2.65</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-2.65</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-2.65</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-2.65</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-2.65</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.05812311</value>
                <inTangent>-0.004861355</inTangent>
                <outTangent>-0.004861355</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.06298447</value>
                <inTangent>-0.00243067741</inTangent>
                <outTangent>-0.00243067741</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.06298447</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.06298447</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.06298447</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.06298447</value>
                <inTangent>-0.1323049</inTangent>
                <outTangent>-0.1323049</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.32759428</value>
                <inTangent>1.2215147</inTangent>
                <outTangent>1.2215147</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1.38004494</value>
                <inTangent>2.70763922</inTangent>
                <outTangent>2.70763922</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-2.611463</value>
                <inTangent>-0.0385370255</inTangent>
                <outTangent>-0.0385370255</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-2.65</value>
                <inTangent>-0.0192685127</inTangent>
                <outTangent>-0.0192685127</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-2.65</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-2.65</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-2.65</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-2.65</value>
                <inTangent>0.513283551</inTangent>
                <outTangent>0.513283551</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.623433</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-2.65</value>
                <inTangent>-1.0265671</inTangent>
                <outTangent>-1.0265671</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.454965353</value>
                <inTangent>0.08178902</inTangent>
                <outTangent>0.08178902</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.373176336</value>
                <inTangent>0.04089451</inTangent>
                <outTangent>0.04089451</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.373176336</value>
                <inTangent>-0.0364583731</inTangent>
                <outTangent>-0.0364583731</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.446093082</value>
                <inTangent>-0.0471619368</inTangent>
                <outTangent>-0.0471619368</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.4675002</value>
                <inTangent>0.132699251</inTangent>
                <outTangent>0.132699251</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.18069458</value>
                <inTangent>0.5134493</inTangent>
                <outTangent>0.5134493</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.5593984</value>
                <inTangent>0.3068521</inTangent>
                <outTangent>0.3068521</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.433009624</value>
                <inTangent>-0.126388788</inTangent>
                <outTangent>-0.126388788</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.8557089</value>
                <inTangent>0.3255381</inTangent>
                <outTangent>0.3255381</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.5301708</value>
                <inTangent>0.131171823</inTangent>
                <outTangent>0.131171823</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.593365252</value>
                <inTangent>-0.226041675</inTangent>
                <outTangent>-0.226041675</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.982254148</value>
                <inTangent>-0.39136222</inTangent>
                <outTangent>-0.39136222</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.37608969</value>
                <inTangent>-0.284417748</inTangent>
                <outTangent>-0.284417748</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.55108964</value>
                <inTangent>0.2965269</inTangent>
                <outTangent>0.2965269</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.7830359</value>
                <inTangent>0.272221327</inTangent>
                <outTangent>0.272221327</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.006647</value>
                <inTangent>-0.223611116</inTangent>
                <outTangent>-0.223611116</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.03605672</value>
                <inTangent>-0.003354948</inTangent>
                <outTangent>-0.003354948</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.03270177</value>
                <inTangent>-0.00108878687</inTangent>
                <outTangent>-0.00108878687</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.033879146</value>
                <inTangent>0.0875434652</inTangent>
                <outTangent>0.0875434652</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.2077887</value>
                <inTangent>0.020817332</inTangent>
                <outTangent>0.020817332</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.0755138</value>
                <inTangent>-0.0453349762</inTangent>
                <outTangent>-0.0453349762</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.117118746</value>
                <inTangent>0.111872673</inTangent>
                <outTangent>0.111872673</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.299259156</value>
                <inTangent>-0.0211335272</inTangent>
                <outTangent>-0.0211335272</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.0748516843</value>
                <inTangent>-0.224407464</inTangent>
                <outTangent>-0.224407464</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.9993498</value>
                <inTangent>-0.000115394592</inTangent>
                <outTangent>-0.000115394592</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.999465168</value>
                <inTangent>-3.8087368E-05</inTangent>
                <outTangent>-3.8087368E-05</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.999425948</value>
                <inTangent>0.0106457174</inTangent>
                <outTangent>0.0106457174</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.978173733</value>
                <inTangent>0.00114059448</inTangent>
                <outTangent>0.00114059448</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.997144759</value>
                <inTangent>-0.007472098</inTangent>
                <outTangent>-0.007472098</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.9931179</value>
                <inTangent>0.0214864314</inTangent>
                <outTangent>0.0214864314</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.9541719</value>
                <inTangent>-0.00203838944</inTangent>
                <outTangent>-0.00203838944</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.9971947</value>
                <inTangent>-0.04302281</inTangent>
                <outTangent>-0.04302281</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.0293977465</value>
                <inTangent>-0.00341451354</inTangent>
                <outTangent>-0.00341451354</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.025983233</value>
                <inTangent>-0.00170725677</inTangent>
                <outTangent>-0.00170725677</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.025983233</value>
                <inTangent>-0.008063547</inTangent>
                <outTangent>-0.008063547</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.009856139</value>
                <inTangent>-0.0430054367</inTangent>
                <outTangent>-0.0430054367</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.06002764</value>
                <inTangent>0.00358373672</inTangent>
                <outTangent>0.00358373672</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.01702361</value>
                <inTangent>0.143971369</inTangent>
                <outTangent>0.143971369</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.2279151</value>
                <inTangent>-0.012818791</inTangent>
                <outTangent>-0.012818791</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.008613971</value>
                <inTangent>-0.236529067</inTangent>
                <outTangent>-0.236529067</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.398351222</value>
                <inTangent>0.000237286091</inTangent>
                <outTangent>0.000237286091</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.3985885</value>
                <inTangent>0.000118643045</inTangent>
                <outTangent>0.000118643045</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.3985885</value>
                <inTangent>-0.0295661241</inTangent>
                <outTangent>-0.0295661241</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.33945626</value>
                <inTangent>-0.02419044</inTangent>
                <outTangent>-0.02419044</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.350207627</value>
                <inTangent>0.0179189146</inTangent>
                <outTangent>0.0179189146</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.3752941</value>
                <inTangent>-0.0110896081</inTangent>
                <outTangent>-0.0110896081</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.3280284</value>
                <inTangent>0.000557631254</inTangent>
                <outTangent>0.000557631254</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.376409352</value>
                <inTangent>0.04838094</inTangent>
                <outTangent>0.04838094</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
      </item>
    </curves>
    <name>Punch3</name>
    <attacking>LeftArm</attacking>
    <defending>RightArm</defending>
    <length>1.1</length>
  </punch3>
  <kick1>
    <curves>
      <item>
        <key>
          <string>Armaturem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.0588889122</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.0966663361</value>
                <inTangent>0.195276976</inTangent>
                <outTangent>0.195276976</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.331665039</value>
                <inTangent>0.117499352</inTangent>
                <outTangent>0.117499352</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.331665039</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.389055729</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.361421347</value>
                <inTangent>-0.375238538</inTangent>
                <outTangent>-0.375238538</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.361421347</value>
                <inTangent>0.165277839</inTangent>
                <outTangent>0.165277839</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.03086567</value>
                <inTangent>0.330555677</inTangent>
                <outTangent>0.330555677</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.5859723</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.63944453</value>
                <inTangent>0.04298532</inTangent>
                <outTangent>0.04298532</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.500001669</value>
                <inTangent>0.06972143</inTangent>
                <outTangent>0.06972143</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.500001669</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.5432335</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.789027154</value>
                <inTangent>-0.12289682</inTangent>
                <outTangent>-0.12289682</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.789027154</value>
                <inTangent>-0.2114583</inTangent>
                <outTangent>-0.2114583</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.21194375</value>
                <inTangent>-0.4229166</inTangent>
                <outTangent>-0.4229166</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9994357</value>
                <inTangent>-0.285294354</inTangent>
                <outTangent>-0.285294354</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.7143081</value>
                <inTangent>-0.1702129</inTangent>
                <outTangent>-0.1702129</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.659009933</value>
                <inTangent>-0.126016244</inTangent>
                <outTangent>-0.126016244</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.4622756</value>
                <inTangent>-0.09836717</inTangent>
                <outTangent>-0.09836717</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.2914287</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.6995449</value>
                <inTangent>0.327825665</inTangent>
                <outTangent>0.327825665</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.94708</value>
                <inTangent>0.1477421</inTangent>
                <outTangent>0.1477421</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.9950291</value>
                <inTangent>0.0479490757</inTangent>
                <outTangent>0.0479490757</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.033590395</value>
                <inTangent>-0.7335919</inTangent>
                <outTangent>-0.7335919</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.699831367</value>
                <inTangent>-0.39286232</inTangent>
                <outTangent>-0.39286232</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.752134264</value>
                <inTangent>-0.09345248</inTangent>
                <outTangent>-0.09345248</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.886736333</value>
                <inTangent>-0.0673010349</inTangent>
                <outTangent>-0.0673010349</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.95659256</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.714588642</value>
                <inTangent>0.317797422</inTangent>
                <outTangent>0.317797422</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.320997715</value>
                <inTangent>-0.3075019</inTangent>
                <outTangent>-0.3075019</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.09958478</value>
                <inTangent>0.4205825</inTangent>
                <outTangent>0.4205825</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.00252750167</value>
                <inTangent>0.62161994</inTangent>
                <outTangent>0.62161994</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.6239378</value>
                <inTangent>0.2708919</inTangent>
                <outTangent>0.2708919</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.5443113</value>
                <inTangent>-0.232579187</inTangent>
                <outTangent>-0.232579187</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.158779413</value>
                <inTangent>-0.192765936</inTangent>
                <outTangent>-0.192765936</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.008623898</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.5964021</value>
                <inTangent>0.37871024</inTangent>
                <outTangent>0.37871024</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.7660444</value>
                <inTangent>-0.2958322</inTangent>
                <outTangent>-0.2958322</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.0047377497</value>
                <inTangent>-0.761306643</inTangent>
                <outTangent>-0.761306643</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.999996841</value>
                <inTangent>0.218690157</inTangent>
                <outTangent>0.218690157</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.781474054</value>
                <inTangent>0.08055675</inTangent>
                <outTangent>0.08055675</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.83888334</value>
                <inTangent>-0.102920026</inTangent>
                <outTangent>-0.102920026</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.9873141</value>
                <inTangent>-0.07421538</inTangent>
                <outTangent>-0.07421538</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.9999628</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.802685857</value>
                <inTangent>0.178587556</inTangent>
                <outTangent>0.178587556</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.6427877</value>
                <inTangent>-0.09865147</inTangent>
                <outTangent>-0.09865147</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.9999888</value>
                <inTangent>-0.3572011</inTangent>
                <outTangent>-0.3572011</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.959901869</value>
                <inTangent>-0.0442408919</inTangent>
                <outTangent>-0.0442408919</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.9001646</value>
                <inTangent>-0.009629697</inTangent>
                <outTangent>-0.009629697</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.9406425</value>
                <inTangent>-0.0987412</inTangent>
                <outTangent>-0.0987412</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.7026822</value>
                <inTangent>-0.118980139</inTangent>
                <outTangent>-0.118980139</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.2771514</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.317440122</value>
                <inTangent>0.278357267</inTangent>
                <outTangent>0.278357267</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.8338659</value>
                <inTangent>-0.311828434</inTangent>
                <outTangent>-0.311828434</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.941097</value>
                <inTangent>-1.7749629</inTangent>
                <outTangent>-1.7749629</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.2803365</value>
                <inTangent>-0.121615022</inTangent>
                <outTangent>-0.121615022</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.4355499</value>
                <inTangent>-0.029531315</inTangent>
                <outTangent>-0.029531315</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.339399129</value>
                <inTangent>-0.137977</inTangent>
                <outTangent>-0.137977</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.7115039</value>
                <inTangent>-0.1860524</inTangent>
                <outTangent>-0.1860524</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.9608263</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.948278368</value>
                <inTangent>0.204429626</inTangent>
                <outTangent>0.204429626</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.551967</value>
                <inTangent>0.305070817</inTangent>
                <outTangent>0.305070817</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.338136733</value>
                <inTangent>0.213830292</inTangent>
                <outTangent>0.213830292</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.8626376</value>
                <inTangent>0.00184285641</inTangent>
                <outTangent>0.00184285641</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.8865916</value>
                <inTangent>-0.00256663561</inTangent>
                <outTangent>-0.00256663561</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.8575043</value>
                <inTangent>-0.111894339</inTangent>
                <outTangent>-0.111894339</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.662802935</value>
                <inTangent>-0.09735069</inTangent>
                <outTangent>-0.09735069</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.424374551</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.6677328</value>
                <inTangent>-0.0402697027</inTangent>
                <outTangent>-0.0402697027</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.343835145</value>
                <inTangent>-0.135794863</inTangent>
                <outTangent>-0.135794863</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.396143049</value>
                <inTangent>0.0523079038</inTangent>
                <outTangent>0.0523079038</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.5058225</value>
                <inTangent>-0.00315600634</inTangent>
                <outTangent>-0.00315600634</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.462553144</value>
                <inTangent>0.0043271184</inTangent>
                <outTangent>0.0043271184</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.5144767</value>
                <inTangent>0.143120408</inTangent>
                <outTangent>0.143120408</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.74879396</value>
                <inTangent>0.117158622</inTangent>
                <outTangent>0.117158622</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.9054867</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.7444011</value>
                <inTangent>0.0167716742</inTangent>
                <outTangent>0.0167716742</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.939030051</value>
                <inTangent>0.831295</inTangent>
                <outTangent>0.831295</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.91818887</value>
                <inTangent>-0.0208411813</inTangent>
                <outTangent>-0.0208411813</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.9604132</value>
                <inTangent>-0.039518714</inTangent>
                <outTangent>-0.039518714</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9974688</value>
                <inTangent>-0.0181293786</inTangent>
                <outTangent>-0.0181293786</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.996672</value>
                <inTangent>0.178688258</inTangent>
                <outTangent>0.178688258</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.6400923</value>
                <inTangent>0.178289831</inTangent>
                <outTangent>0.178289831</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.146373242</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.269025415</value>
                <inTangent>-0.116832495</inTangent>
                <outTangent>-0.116832495</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.380038232</value>
                <inTangent>0.113463059</inTangent>
                <outTangent>0.113463059</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.0420992933</value>
                <inTangent>0.337938935</inTangent>
                <outTangent>0.337938935</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.278579533</value>
                <inTangent>-0.266906261</inTangent>
                <outTangent>-0.266906261</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.0711061358</value>
                <inTangent>-0.180048242</inTangent>
                <outTangent>-0.180048242</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.08151696</value>
                <inTangent>-0.348595917</inTangent>
                <outTangent>-0.348595917</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.768298</value>
                <inTangent>-0.3433905</inTangent>
                <outTangent>-0.3433905</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.989229441</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.9631331</value>
                <inTangent>0.957100153</inTangent>
                <outTangent>0.957100153</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.9249708</value>
                <inTangent>-0.0179901123</inTangent>
                <outTangent>-0.0179901123</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.999113441</value>
                <inTangent>-1.92408419</inTangent>
                <outTangent>-1.92408419</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.96620214</value>
                <inTangent>-0.0571197271</inTangent>
                <outTangent>-0.0571197271</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.858537555</value>
                <inTangent>-0.019562155</inTangent>
                <outTangent>-0.019562155</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.92707783</value>
                <inTangent>-0.106159955</inTangent>
                <outTangent>-0.106159955</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.646217644</value>
                <inTangent>-0.1404301</inTangent>
                <outTangent>-0.1404301</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.7274703</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.45250228</value>
                <inTangent>0.0459891558</inTangent>
                <outTangent>0.0459891558</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.8194486</value>
                <inTangent>0.713106632</inTangent>
                <outTangent>0.713106632</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.9737111</value>
                <inTangent>0.154262483</inTangent>
                <outTangent>0.154262483</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.257785827</value>
                <inTangent>-0.158830673</inTangent>
                <outTangent>-0.158830673</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.512750864</value>
                <inTangent>-0.05854158</inTangent>
                <outTangent>-0.05854158</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.374869</value>
                <inTangent>0.637952</inTangent>
                <outTangent>0.637952</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.763153255</value>
                <inTangent>0.5690111</inTangent>
                <outTangent>0.5690111</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.6861392</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.8917633</value>
                <inTangent>-0.629646</inTangent>
                <outTangent>-0.629646</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.5731528</value>
                <inTangent>0.3319883</inTangent>
                <outTangent>0.3319883</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.22778672</value>
                <inTangent>0.345366061</inTangent>
                <outTangent>0.345366061</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9533692</value>
                <inTangent>-0.001817584</inTangent>
                <outTangent>-0.001817584</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.9539536</value>
                <inTangent>0.00485280156</inTangent>
                <outTangent>0.00485280156</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.9630748</value>
                <inTangent>-0.2824319</inTangent>
                <outTangent>-0.2824319</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.389089823</value>
                <inTangent>-0.2869925</inTangent>
                <outTangent>-0.2869925</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.275512725</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.6491544</value>
                <inTangent>0.401212215</inTangent>
                <outTangent>0.401212215</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.526911736</value>
                <inTangent>-0.2004022</inTangent>
                <outTangent>-0.2004022</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.248350009</value>
                <inTangent>-0.2785617</inTangent>
                <outTangent>-0.2785617</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.301806569</value>
                <inTangent>0.00568243861</inTangent>
                <outTangent>0.00568243861</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.299954146</value>
                <inTangent>-0.0162861943</inTangent>
                <outTangent>-0.0162861943</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.26923418</value>
                <inTangent>-0.610577047</inTangent>
                <outTangent>-0.610577047</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.9211999</value>
                <inTangent>-0.595217049</inTangent>
                <outTangent>-0.595217049</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.9612975</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.7606567</value>
                <inTangent>-0.05568874</inTangent>
                <outTangent>-0.05568874</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.849920034</value>
                <inTangent>0.8646636</inTangent>
                <outTangent>0.8646636</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.9686704</value>
                <inTangent>0.118750393</inTangent>
                <outTangent>0.118750393</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.9966414</value>
                <inTangent>0.0204235315</inTangent>
                <outTangent>0.0204235315</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.94436723</value>
                <inTangent>0.03537059</inTangent>
                <outTangent>0.03537059</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.9259002</value>
                <inTangent>0.306836277</inTangent>
                <outTangent>0.306836277</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.3306947</value>
                <inTangent>0.297602773</inTangent>
                <outTangent>0.297602773</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.229107574</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.1009814</value>
                <inTangent>-0.175731465</inTangent>
                <outTangent>-0.175731465</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.5805705</value>
                <inTangent>-0.133246824</inTangent>
                <outTangent>-0.133246824</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.367475063</value>
                <inTangent>0.213095456</inTangent>
                <outTangent>0.213095456</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.08188956</value>
                <inTangent>-0.298681468</inTangent>
                <outTangent>-0.298681468</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.328892976</value>
                <inTangent>-0.2298288</inTangent>
                <outTangent>-0.2298288</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.37776804</value>
                <inTangent>-0.3074224</inTangent>
                <outTangent>-0.3074224</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.943737745</value>
                <inTangent>-0.282984853</inTangent>
                <outTangent>-0.282984853</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.97340107</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.994888365</value>
                <inTangent>-0.0795955658</inTangent>
                <outTangent>-0.0795955658</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.814209938</value>
                <inTangent>0.9624609</inTangent>
                <outTangent>0.9624609</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.9300334</value>
                <inTangent>0.115823448</inTangent>
                <outTangent>0.115823448</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>7.473863E-08</value>
                <inTangent>-1.51339918E-08</inTangent>
                <outTangent>-1.51339918E-08</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.78813934E-07</value>
                <inTangent>-1.11875124E-07</inTangent>
                <outTangent>-1.11875124E-07</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.49011612E-07</value>
                <inTangent>2.98023224E-08</inTangent>
                <outTangent>2.98023224E-08</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.1920929E-07</value>
                <inTangent>1.49011612E-08</inTangent>
                <outTangent>1.49011612E-08</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.49011612E-07</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0.0001219362</inTangent>
                <outTangent>0.0001219362</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.000243723392</value>
                <inTangent>3.7252903E-08</inTangent>
                <outTangent>3.7252903E-08</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>7.4505806E-08</value>
                <inTangent>-0.000243648887</inTangent>
                <outTangent>-0.000243648887</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.0603703447</value>
                <inTangent>0.6977931</inTangent>
                <outTangent>0.6977931</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.7793482</value>
                <inTangent>0.437933445</inTangent>
                <outTangent>0.437933445</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.9362372</value>
                <inTangent>0.08610904</inTangent>
                <outTangent>0.08610904</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.9515663</value>
                <inTangent>0.00766453147</inTangent>
                <outTangent>0.00766453147</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.9982957</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.4165717</value>
                <inTangent>-0.44883424</inTangent>
                <outTangent>-0.44883424</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.100627311</value>
                <inTangent>-0.178441465</inTangent>
                <outTangent>-0.178441465</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.05968879</value>
                <inTangent>-0.04093852</inTangent>
                <outTangent>-0.04093852</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.998176038</value>
                <inTangent>1.65024066</inTangent>
                <outTangent>1.65024066</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.6265911</value>
                <inTangent>0.6747723</inTangent>
                <outTangent>0.6747723</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.3513687</value>
                <inTangent>-0.1595737</inTangent>
                <outTangent>-0.1595737</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.307443678</value>
                <inTangent>-0.0219625086</inTangent>
                <outTangent>-0.0219625086</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.05835808</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.909102857</value>
                <inTangent>0.52664113</inTangent>
                <outTangent>0.52664113</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.9949242</value>
                <inTangent>0.9536599</inTangent>
                <outTangent>0.9536599</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.998217046</value>
                <inTangent>0.0032928586</inTangent>
                <outTangent>0.0032928586</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.306661636</value>
                <inTangent>-0.404182762</inTangent>
                <outTangent>-0.404182762</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.774347365</value>
                <inTangent>-0.294360638</inTangent>
                <outTangent>-0.294360638</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.8953829</value>
                <inTangent>0.3865593</inTangent>
                <outTangent>0.3865593</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00122872007</value>
                <inTangent>0.447077066</inTangent>
                <outTangent>0.447077066</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00147485733</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.8891212</value>
                <inTangent>0.248644888</inTangent>
                <outTangent>0.248644888</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.495814979</value>
                <inTangent>0.764413</inTangent>
                <outTangent>0.764413</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.639704943</value>
                <inTangent>0.143889964</inTangent>
                <outTangent>0.143889964</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.951818645</value>
                <inTangent>-0.248469353</inTangent>
                <outTangent>-0.248469353</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.6327608</value>
                <inTangent>-0.2532607</inTangent>
                <outTangent>-0.2532607</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.445297241</value>
                <inTangent>0.183619231</inTangent>
                <outTangent>0.183619231</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.9999993</value>
                <inTangent>0.277351022</inTangent>
                <outTangent>0.277351022</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.9999989</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.45767194</value>
                <inTangent>-0.9342135</inTangent>
                <outTangent>-0.9342135</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.86842823</value>
                <inTangent>-0.613146245</inTangent>
                <outTangent>-0.613146245</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.7686206</value>
                <inTangent>0.09980762</inTangent>
                <outTangent>0.09980762</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.363563925</value>
                <inTangent>0.29382664</inTangent>
                <outTangent>0.29382664</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.007941842</value>
                <inTangent>0.1306348</inTangent>
                <outTangent>0.1306348</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.102294326</value>
                <inTangent>-0.471623</inTangent>
                <outTangent>-0.471623</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.951187849</value>
                <inTangent>-0.424446762</inTangent>
                <outTangent>-0.424446762</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.9983807</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.9989558</value>
                <inTangent>0.296235</inTangent>
                <outTangent>0.296235</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.40591073</value>
                <inTangent>0.203134716</inTangent>
                <outTangent>0.203134716</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.592686355</value>
                <inTangent>-0.186775625</inTangent>
                <outTangent>-0.186775625</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.9315693</value>
                <inTangent>1.92913473</inTangent>
                <outTangent>1.92913473</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.9999685</value>
                <inTangent>0.9631618</inTangent>
                <outTangent>0.9631618</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.994754255</value>
                <inTangent>-0.345677942</inTangent>
                <outTangent>-0.345677942</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.308612674</value>
                <inTangent>-0.3430708</inTangent>
                <outTangent>-0.3430708</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.0568856746</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.045688156</value>
                <inTangent>-0.428513557</inTangent>
                <outTangent>-0.428513557</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.9139128</value>
                <inTangent>-0.37987262</inTangent>
                <outTangent>-0.37987262</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.8054334</value>
                <inTangent>0.108479381</inTangent>
                <outTangent>0.108479381</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.3459592</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.3882516</value>
                <inTangent>-0.147063717</inTangent>
                <outTangent>-0.147063717</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.0518317632</value>
                <inTangent>0.224518135</inTangent>
                <outTangent>0.224518135</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.8372879</value>
                <inTangent>0.39272806</inTangent>
                <outTangent>0.39272806</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.8633775</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.9959389</value>
                <inTangent>-0.330281466</inTangent>
                <outTangent>-0.330281466</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.202814609</value>
                <inTangent>-0.18664901</inTangent>
                <outTangent>-0.18664901</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.6226409</value>
                <inTangent>0.4198263</inTangent>
                <outTangent>0.4198263</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9382496</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.921553433</value>
                <inTangent>-0.9684527</inTangent>
                <outTangent>-0.9684527</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.998655856</value>
                <inTangent>-0.187395573</inTangent>
                <outTangent>-0.187395573</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.5467623</value>
                <inTangent>0.7727091</inTangent>
                <outTangent>0.7727091</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.5045585</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.09003182</value>
                <inTangent>-0.741887867</inTangent>
                <outTangent>-0.741887867</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.9792172</value>
                <inTangent>0.346237957</inTangent>
                <outTangent>0.346237957</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.7825077</value>
                <inTangent>1.761725</inTangent>
                <outTangent>1.761725</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.290746629</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.422591716</value>
                <inTangent>0.1419865</inTangent>
                <outTangent>0.1419865</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.006773662</value>
                <inTangent>0.2580641</inTangent>
                <outTangent>0.2580641</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.938719869</value>
                <inTangent>0.47274676</inTangent>
                <outTangent>0.47274676</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.000181674957</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.782671154</value>
                <inTangent>-0.392166138</inTangent>
                <outTangent>-0.392166138</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.7841506</value>
                <inTangent>0.7558557</inTangent>
                <outTangent>0.7558557</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.7290401</value>
                <inTangent>1.51319075</inTangent>
                <outTangent>1.51319075</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9568001</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9063202</value>
                <inTangent>0.0215885043</inTangent>
                <outTangent>0.0215885043</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.9999771</value>
                <inTangent>0.2808196</inTangent>
                <outTangent>0.2808196</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.344681054</value>
                <inTangent>-0.672329068</inTangent>
                <outTangent>-0.672329068</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.6224355</value>
                <inTangent>-0.1897147</inTangent>
                <outTangent>-0.1897147</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.6205706</value>
                <inTangent>-0.6534533</inTangent>
                <outTangent>-0.6534533</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.6844711</value>
                <inTangent>-1.30504167</inTangent>
                <outTangent>-1.30504167</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.0582208931</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.0375605524</value>
                <inTangent>-0.0001871176</inTangent>
                <outTangent>-0.0001871176</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.05859513</value>
                <inTangent>-0.0935493857</inTangent>
                <outTangent>-0.0935493857</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.224659324</value>
                <inTangent>-0.0830321</inTangent>
                <outTangent>-0.0830321</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.8634692</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.5494424</value>
                <inTangent>-0.0151226819</inTangent>
                <outTangent>-0.0151226819</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.893714547</value>
                <inTangent>0.202571273</inTangent>
                <outTangent>0.202571273</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.144299835</value>
                <inTangent>0.7494147</inTangent>
                <outTangent>0.7494147</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9983038</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9992944</value>
                <inTangent>-0.9982928</inTangent>
                <outTangent>-0.9982928</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.998281837</value>
                <inTangent>0.0124284923</inTangent>
                <outTangent>0.0124284923</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.9744374</value>
                <inTangent>0.01192221</inTangent>
                <outTangent>0.01192221</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.5044016</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.8355316</value>
                <inTangent>-0.4765189</inTangent>
                <outTangent>-0.4765189</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.4486362</value>
                <inTangent>-0.9125328</inTangent>
                <outTangent>-0.9125328</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.989534</value>
                <inTangent>-0.540897846</inTangent>
                <outTangent>-0.540897846</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1.06147075</value>
                <inTangent>-0.8069444</inTangent>
                <outTangent>-0.8069444</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.254526377</value>
                <inTangent>-0.350000024</inTangent>
                <outTangent>-0.350000024</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.3614707</value>
                <inTangent>-1.09861124</inTangent>
                <outTangent>-1.09861124</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.94269609</value>
                <inTangent>-1.1520834</inTangent>
                <outTangent>-1.1520834</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.3755064</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.4318161</value>
                <inTangent>1.04223</inTangent>
                <outTangent>1.04223</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.7089536</value>
                <inTangent>-0.597889662</inTangent>
                <outTangent>-0.597889662</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.7639632</value>
                <inTangent>-1.47291684</inTangent>
                <outTangent>-1.47291684</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.452485561</value>
                <inTangent>0.111805558</inTangent>
                <outTangent>0.111805558</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.5642911</value>
                <inTangent>0.116666615</inTangent>
                <outTangent>0.116666615</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.6858188</value>
                <inTangent>-0.371875048</inTangent>
                <outTangent>-0.371875048</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.179458976</value>
                <inTangent>-0.432638884</inTangent>
                <outTangent>-0.432638884</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-2.83954215</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-2.89164686</value>
                <inTangent>0.419600844</inTangent>
                <outTangent>0.419600844</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-2.00034046</value>
                <inTangent>0.4164865</inTangent>
                <outTangent>0.4164865</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-2.05867386</value>
                <inTangent>-0.0583333969</inTangent>
                <outTangent>-0.0583333969</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.6681943</value>
                <inTangent>-0.879861355</inTangent>
                <outTangent>-0.879861355</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.211667061</value>
                <inTangent>-0.4545139</inTangent>
                <outTangent>-0.4545139</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.240833521</value>
                <inTangent>-0.4958334</inTangent>
                <outTangent>-0.4958334</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.20333385</value>
                <inTangent>-0.481250167</inTangent>
                <outTangent>-0.481250167</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1.198055</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.8772216</value>
                <inTangent>-0.020943284</inTangent>
                <outTangent>-0.020943284</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1.15616846</value>
                <inTangent>0.1929456</inTangent>
                <outTangent>0.1929456</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1.26311278</value>
                <inTangent>0.106944323</inTangent>
                <outTangent>0.106944323</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.684721947</value>
                <inTangent>-0.09722233</inTangent>
                <outTangent>-0.09722233</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.5874996</value>
                <inTangent>-0.06562507</inTangent>
                <outTangent>-0.06562507</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.5534718</value>
                <inTangent>-1.70381927</inTangent>
                <outTangent>-1.70381927</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-2.820139</value>
                <inTangent>-1.68680537</inTangent>
                <outTangent>-1.68680537</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-2.95625</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-2.888194</value>
                <inTangent>0.674402535</inTangent>
                <outTangent>0.674402535</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.60744488</value>
                <inTangent>0.3851664</inTangent>
                <outTangent>0.3851664</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-2.11786127</value>
                <inTangent>-0.5104164</inTangent>
                <outTangent>-0.5104164</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.7216666</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.7216666</value>
                <inTangent>-0.3122778</inTangent>
                <outTangent>-0.3122778</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.09711099</value>
                <inTangent>0.0085555315</inTangent>
                <outTangent>0.0085555315</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.738777637</value>
                <inTangent>0.320833325</inTangent>
                <outTangent>0.320833325</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>2.45810366</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.8875401</value>
                <inTangent>-2.07833743</inTangent>
                <outTangent>-2.07833743</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.69857121</value>
                <inTangent>-0.138541818</inTangent>
                <outTangent>-0.138541818</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.610456467</value>
                <inTangent>2.30902767</inTangent>
                <outTangent>2.30902767</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-2.59888887</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-2.59888887</value>
                <inTangent>-0.02555561</inTangent>
                <outTangent>-0.02555561</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-2.65</value>
                <inTangent>0.679305434</inTangent>
                <outTangent>0.679305434</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.240278</value>
                <inTangent>0.704861045</inTangent>
                <outTangent>0.704861045</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.526397347</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.35863167</value>
                <inTangent>-0.8168201</inTangent>
                <outTangent>-0.8168201</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.10724282</value>
                <inTangent>-1.14568424</inTangent>
                <outTangent>-1.14568424</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-2.65</value>
                <inTangent>-1.54275727</inTangent>
                <outTangent>-1.54275727</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.9780555</value>
                <inTangent>2.07083344</inTangent>
                <outTangent>2.07083344</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1.092778</value>
                <inTangent>1.10347247</inTangent>
                <outTangent>1.10347247</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1.22888947</value>
                <inTangent>0.326037169</inTangent>
                <outTangent>0.326037169</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1.7448523</value>
                <inTangent>0.25798142</inTangent>
                <outTangent>0.25798142</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.111034393</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.23269892</value>
                <inTangent>-0.5722139</inTangent>
                <outTangent>-0.5722139</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.03339338</value>
                <inTangent>0.09965277</inTangent>
                <outTangent>0.09965277</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.03339338</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-2.53569412</value>
                <inTangent>0.8458333</inTangent>
                <outTangent>0.8458333</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.68986082</value>
                <inTangent>0.7753471</inTangent>
                <outTangent>0.7753471</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.9849999</value>
                <inTangent>1.52325892</inTangent>
                <outTangent>1.52325892</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1.356657</value>
                <inTangent>1.17082846</inTangent>
                <outTangent>1.17082846</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1.77344179</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.372713923</value>
                <inTangent>-2.211721</inTangent>
                <outTangent>-2.211721</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-2.65</value>
                <inTangent>-1.138643</inTangent>
                <outTangent>-1.138643</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-2.65</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.0588889122</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.0966663361</value>
                <inTangent>0.195276976</inTangent>
                <outTangent>0.195276976</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.331665039</value>
                <inTangent>0.117499352</inTangent>
                <outTangent>0.117499352</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.331665039</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.389055729</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.361421347</value>
                <inTangent>-0.375238538</inTangent>
                <outTangent>-0.375238538</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.361421347</value>
                <inTangent>0.165277839</inTangent>
                <outTangent>0.165277839</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.03086567</value>
                <inTangent>0.330555677</inTangent>
                <outTangent>0.330555677</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.5859723</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.63944453</value>
                <inTangent>0.04298532</inTangent>
                <outTangent>0.04298532</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.500001669</value>
                <inTangent>0.06972143</inTangent>
                <outTangent>0.06972143</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.500001669</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.5432335</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.789027154</value>
                <inTangent>-0.12289682</inTangent>
                <outTangent>-0.12289682</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.789027154</value>
                <inTangent>-0.2114583</inTangent>
                <outTangent>-0.2114583</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-1.21194375</value>
                <inTangent>-0.4229166</inTangent>
                <outTangent>-0.4229166</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.03611629</value>
                <inTangent>-0.137302756</inTangent>
                <outTangent>-0.137302756</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.101216108</value>
                <inTangent>-0.154181242</inTangent>
                <outTangent>-0.154181242</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.2722462</value>
                <inTangent>-0.350435585</inTangent>
                <outTangent>-0.350435585</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.8020873</value>
                <inTangent>-0.264920533</inTangent>
                <outTangent>-0.264920533</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.9540437</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.990800261</value>
                <inTangent>0.7366078</inTangent>
                <outTangent>0.7366078</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.519171834</value>
                <inTangent>-0.443251252</inTangent>
                <outTangent>-0.443251252</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.104297787</value>
                <inTangent>-0.414874047</inTangent>
                <outTangent>-0.414874047</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.9993476</value>
                <inTangent>0.00448012352</inTangent>
                <outTangent>0.00448012352</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.994864464</value>
                <inTangent>0.0185599923</inTangent>
                <outTangent>0.0185599923</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.962227643</value>
                <inTangent>0.198828846</inTangent>
                <outTangent>0.198828846</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.5972068</value>
                <inTangent>0.182510436</inTangent>
                <outTangent>0.182510436</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.299667537</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.135332614</value>
                <inTangent>-0.277501166</inTangent>
                <outTangent>-0.277501166</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.854669869</value>
                <inTangent>-0.429606736</inTangent>
                <outTangent>-0.429606736</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>-0.9945461</value>
                <inTangent>-0.139876246</inTangent>
                <outTangent>-0.139876246</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.0307749938</value>
                <inTangent>-0.2311534</inTangent>
                <outTangent>-0.2311534</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.2003784</value>
                <inTangent>-0.152310342</inTangent>
                <outTangent>-0.152310342</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.2738457</value>
                <inTangent>-0.07615518</inTangent>
                <outTangent>-0.07615518</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.35268876</value>
                <inTangent>-0.03942153</inTangent>
                <outTangent>-0.03942153</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.230931938</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.21369037</value>
                <inTangent>0.158274233</inTangent>
                <outTangent>0.158274233</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.08561654</value>
                <inTangent>-0.0642623</inTangent>
                <outTangent>-0.0642623</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.08516577</value>
                <inTangent>-0.000450767577</inTangent>
                <outTangent>-0.000450767577</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.385964781</value>
                <inTangent>-0.155894175</inTangent>
                <outTangent>-0.155894175</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.2300706</value>
                <inTangent>-0.10392946</inTangent>
                <outTangent>-0.10392946</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.178105861</value>
                <inTangent>-0.190836042</inTangent>
                <outTangent>-0.190836042</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.151601449</value>
                <inTangent>-0.164853662</inTangent>
                <outTangent>-0.164853662</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.3259116</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.226949021</value>
                <inTangent>0.251667738</inTangent>
                <outTangent>0.251667738</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.17742385</value>
                <inTangent>0.3085993</inTangent>
                <outTangent>0.3085993</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0.39024958</value>
                <inTangent>0.21282573</inTangent>
                <outTangent>0.21282573</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>7</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
      </item>
    </curves>
    <name>Kick1</name>
    <attacking>LeftLeg</attacking>
    <defending>RightLeg</defending>
    <length>1</length>
  </kick1>
  <kick2>
    <curves>
      <item>
        <key>
          <string>Armaturem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.03086567</value>
                <inTangent>0.6287885</inTangent>
                <outTangent>0.6287885</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.5979228</value>
                <inTangent>-0.0672029257</inTangent>
                <outTangent>-0.0672029257</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.165271521</value>
                <inTangent>-0.7631943</inTangent>
                <outTangent>-0.7631943</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.21194375</value>
                <inTangent>0.2618258</inTangent>
                <outTangent>0.2618258</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.950117946</value>
                <inTangent>0.113898993</inTangent>
                <outTangent>0.113898993</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.984145761</value>
                <inTangent>-0.0340278149</inTangent>
                <outTangent>-0.0340278149</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9950291</value>
                <inTangent>0.00315433741</inTangent>
                <outTangent>0.00315433741</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.9981834</value>
                <inTangent>0.00061288476</inTangent>
                <outTangent>0.00061288476</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.996254861</value>
                <inTangent>-0.00192856789</inTangent>
                <outTangent>-0.00192856789</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.09958478</value>
                <inTangent>-0.0393366</inTangent>
                <outTangent>-0.0393366</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.06024818</value>
                <inTangent>-0.00655966252</inTangent>
                <outTangent>-0.00655966252</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.0864654556</value>
                <inTangent>0.0262172744</inTangent>
                <outTangent>0.0262172744</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.0047377497</value>
                <inTangent>0.06572891</inTangent>
                <outTangent>0.06572891</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.07046666</value>
                <inTangent>0.0108813681</inTangent>
                <outTangent>0.0108813681</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.0265004858</value>
                <inTangent>-0.0439661741</inTangent>
                <outTangent>-0.0439661741</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.9999888</value>
                <inTangent>0.00247466564</inTangent>
                <outTangent>0.00247466564</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9975141</value>
                <inTangent>0.000169992447</inTangent>
                <outTangent>0.000169992447</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.9996488</value>
                <inTangent>-0.00213468075</inTangent>
                <outTangent>-0.00213468075</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.941097</value>
                <inTangent>-0.00665199757</inTangent>
                <outTangent>-0.00665199757</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.947749</value>
                <inTangent>-0.00390446186</inTangent>
                <outTangent>-0.00390446186</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.948905945</value>
                <inTangent>-0.00115692616</inTangent>
                <outTangent>-0.00115692616</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.338136733</value>
                <inTangent>0.01911971</inTangent>
                <outTangent>0.01911971</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.319017023</value>
                <inTangent>0.0112888217</inTangent>
                <outTangent>0.0112888217</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.3155591</value>
                <inTangent>0.00345793366</inTangent>
                <outTangent>0.00345793366</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.396143049</value>
                <inTangent>-0.0615791976</inTangent>
                <outTangent>-0.0615791976</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.334563851</value>
                <inTangent>-0.4977646</inTangent>
                <outTangent>-0.4977646</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.5993861</value>
                <inTangent>-0.933949947</inTangent>
                <outTangent>-0.933949947</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.91818887</value>
                <inTangent>0.024184227</inTangent>
                <outTangent>0.024184227</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.9423731</value>
                <inTangent>-0.859324455</inTangent>
                <outTangent>-0.859324455</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.8004601</value>
                <inTangent>-1.74283314</inTangent>
                <outTangent>-1.74283314</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.0420992933</value>
                <inTangent>-0.07201116</inTangent>
                <outTangent>-0.07201116</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.114110455</value>
                <inTangent>-0.0825009644</inTangent>
                <outTangent>-0.0825009644</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.207101226</value>
                <inTangent>-0.09299077</inTangent>
                <outTangent>-0.09299077</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.999113441</value>
                <inTangent>0.00564539433</inTangent>
                <outTangent>0.00564539433</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.993468046</value>
                <inTangent>0.9887165</inTangent>
                <outTangent>0.9887165</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.9783195</value>
                <inTangent>1.97178757</inTangent>
                <outTangent>1.97178757</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9737111</value>
                <inTangent>-1.840605</inTangent>
                <outTangent>-1.840605</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.866893947</value>
                <inTangent>-0.8827962</inTangent>
                <outTangent>-0.8827962</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.7918814</value>
                <inTangent>0.0750125647</inTangent>
                <outTangent>0.0750125647</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.22778672</value>
                <inTangent>-0.270705938</inTangent>
                <outTangent>-0.270705938</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.498492658</value>
                <inTangent>-0.191444159</inTangent>
                <outTangent>-0.191444159</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.610675037</value>
                <inTangent>-0.112182379</inTangent>
                <outTangent>-0.112182379</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.248350009</value>
                <inTangent>0.103065386</inTangent>
                <outTangent>0.103065386</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.3514154</value>
                <inTangent>-0.4898681</inTangent>
                <outTangent>-0.4898681</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.731386244</value>
                <inTangent>-1.08280158</inTangent>
                <outTangent>-1.08280158</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9686704</value>
                <inTangent>-0.0324507952</inTangent>
                <outTangent>-0.0324507952</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.936219633</value>
                <inTangent>-0.825316966</inTangent>
                <outTangent>-0.825316966</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.681963563</value>
                <inTangent>-1.61818314</inTangent>
                <outTangent>-1.61818314</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.367475063</value>
                <inTangent>0.0780880451</inTangent>
                <outTangent>0.0780880451</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.289387017</value>
                <inTangent>0.1585669</inTangent>
                <outTangent>0.1585669</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.05034124</value>
                <inTangent>0.239045769</inTangent>
                <outTangent>0.239045769</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9300334</value>
                <inTangent>-1.88724566</inTangent>
                <outTangent>-1.88724566</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9572122</value>
                <inTangent>0.0343493223</inTangent>
                <outTangent>0.0343493223</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.9987321</value>
                <inTangent>1.9559443</inTangent>
                <outTangent>1.9559443</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>7.4505806E-08</value>
                <inTangent>2.98023224E-08</inTangent>
                <outTangent>2.98023224E-08</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1.04308128E-07</value>
                <inTangent>-1.2293458E-07</inTangent>
                <outTangent>-1.2293458E-07</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.71363354E-07</value>
                <inTangent>-2.75671482E-07</inTangent>
                <outTangent>-2.75671482E-07</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.05968879</value>
                <inTangent>0.2740894</inTangent>
                <outTangent>0.2740894</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.3337782</value>
                <inTangent>0.271268129</inTangent>
                <outTangent>0.271268129</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.602225065</value>
                <inTangent>0.268446863</inTangent>
                <outTangent>0.268446863</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.998217046</value>
                <inTangent>-1.94086862</inTangent>
                <outTangent>-1.94086862</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9426516</value>
                <inTangent>-0.09994531</inTangent>
                <outTangent>-0.09994531</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.7983264</value>
                <inTangent>1.740978</inTangent>
                <outTangent>1.740978</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.639704943</value>
                <inTangent>-0.7246167</inTangent>
                <outTangent>-0.7246167</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.08491174</value>
                <inTangent>0.000957191</inTangent>
                <outTangent>0.000957191</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.6416193</value>
                <inTangent>0.7265311</inTangent>
                <outTangent>0.7265311</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.7686206</value>
                <inTangent>1.76500916</inTangent>
                <outTangent>1.76500916</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.9963885</value>
                <inTangent>0.00079870224</inTangent>
                <outTangent>0.00079870224</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.767023265</value>
                <inTangent>-1.76341176</inTangent>
                <outTangent>-1.76341176</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.592686355</value>
                <inTangent>0.180071414</inTangent>
                <outTangent>0.180071414</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.412614942</value>
                <inTangent>0.27119267</inTangent>
                <outTangent>0.27119267</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.0503009856</value>
                <inTangent>0.362313956</inTangent>
                <outTangent>0.362313956</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.8054334</value>
                <inTangent>-0.105472147</inTangent>
                <outTangent>-0.105472147</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.91090554</value>
                <inTangent>-0.09665036</inTangent>
                <outTangent>-0.09665036</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.9987341</value>
                <inTangent>-0.08782858</inTangent>
                <outTangent>-0.08782858</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.6226409</value>
                <inTangent>0.2631839</inTangent>
                <outTangent>0.2631839</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.8858248</value>
                <inTangent>-0.227978617</inTangent>
                <outTangent>-0.227978617</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.166683659</value>
                <inTangent>-0.7191411</inTangent>
                <outTangent>-0.7191411</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.7825077</value>
                <inTangent>-0.318487853</inTangent>
                <outTangent>-0.318487853</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.464019865</value>
                <inTangent>0.101751342</inTangent>
                <outTangent>0.101751342</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.986010432</value>
                <inTangent>0.521990538</inTangent>
                <outTangent>0.521990538</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.7290401</value>
                <inTangent>-1.05524564</inTangent>
                <outTangent>-1.05524564</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.326205581</value>
                <inTangent>-0.0539494157</inTangent>
                <outTangent>-0.0539494157</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.6211412</value>
                <inTangent>0.9473468</inTangent>
                <outTangent>0.9473468</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.6844711</value>
                <inTangent>1.62976992</inTangent>
                <outTangent>1.62976992</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.945298851</value>
                <inTangent>-0.0496137738</inTangent>
                <outTangent>-0.0496137738</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.7836987</value>
                <inTangent>-1.72899747</inTangent>
                <outTangent>-1.72899747</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.144299835</value>
                <inTangent>-0.541703463</inTangent>
                <outTangent>-0.541703463</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.6860033</value>
                <inTangent>-0.168761015</inTangent>
                <outTangent>-0.168761015</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.4818219</value>
                <inTangent>0.204181433</inTangent>
                <outTangent>0.204181433</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.989534</value>
                <inTangent>1.71713257</inTangent>
                <outTangent>1.71713257</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.7275985</value>
                <inTangent>0.05663246</inTangent>
                <outTangent>0.05663246</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.876269162</value>
                <inTangent>-1.60386765</inTangent>
                <outTangent>-1.60386765</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.7639632</value>
                <inTangent>0.450082541</inTangent>
                <outTangent>0.450082541</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.313880682</value>
                <inTangent>0.22504127</inTangent>
                <outTangent>0.22504127</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.313880682</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-2.05867386</value>
                <inTangent>0.2991302</inTangent>
                <outTangent>0.2991302</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.75954366</value>
                <inTangent>0.1495651</inTangent>
                <outTangent>0.1495651</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.75954366</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1.26311278</value>
                <inTangent>-2.09513879</inTangent>
                <outTangent>-2.09513879</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.832026</value>
                <inTangent>-1.04756939</inTangent>
                <outTangent>-1.04756939</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.832026</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-2.11786127</value>
                <inTangent>0.855555534</inTangent>
                <outTangent>0.855555534</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.26230574</value>
                <inTangent>0.427777767</inTangent>
                <outTangent>0.427777767</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.26230574</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.610456467</value>
                <inTangent>1.97361112</inTangent>
                <outTangent>1.97361112</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>2.58406758</value>
                <inTangent>-0.7170141</inTangent>
                <outTangent>-0.7170141</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.8235717</value>
                <inTangent>-3.40763927</inTangent>
                <outTangent>-3.40763927</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-2.65</value>
                <inTangent>2.104861</inTangent>
                <outTangent>2.104861</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.545139134</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-2.65</value>
                <inTangent>-2.104861</inTangent>
                <outTangent>-2.104861</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.03339338</value>
                <inTangent>0.111805916</inTangent>
                <outTangent>0.111805916</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.921587467</value>
                <inTangent>0.8555558</inTangent>
                <outTangent>0.8555558</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.677718163</value>
                <inTangent>1.59930563</inTangent>
                <outTangent>1.59930563</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-2.65</value>
                <inTangent>0.05347228</inTangent>
                <outTangent>0.05347228</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-2.59652781</value>
                <inTangent>0.06562495</inTangent>
                <outTangent>0.06562495</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-2.51875019</value>
                <inTangent>0.0777776241</inTangent>
                <outTangent>0.0777776241</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.03086567</value>
                <inTangent>0.6287885</inTangent>
                <outTangent>0.6287885</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.5979228</value>
                <inTangent>-0.0672029257</inTangent>
                <outTangent>-0.0672029257</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.165271521</value>
                <inTangent>-0.7631943</inTangent>
                <outTangent>-0.7631943</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.21194375</value>
                <inTangent>0.2618258</inTangent>
                <outTangent>0.2618258</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.950117946</value>
                <inTangent>0.113898993</inTangent>
                <outTangent>0.113898993</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.984145761</value>
                <inTangent>-0.0340278149</inTangent>
                <outTangent>-0.0340278149</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.104297787</value>
                <inTangent>0.02613917</inTangent>
                <outTangent>0.02613917</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.130436957</value>
                <inTangent>0.004269358</inTangent>
                <outTangent>0.004269358</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.1128365</value>
                <inTangent>-0.0176004544</inTangent>
                <outTangent>-0.0176004544</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.9945461</value>
                <inTangent>0.00308948755</inTangent>
                <outTangent>0.00308948755</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9914566</value>
                <inTangent>0.000466257334</inTangent>
                <outTangent>0.000466257334</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.9936136</value>
                <inTangent>-0.002156973</inTangent>
                <outTangent>-0.002156973</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.08516577</value>
                <inTangent>-0.002807133</inTangent>
                <outTangent>-0.002807133</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.082358636</value>
                <inTangent>-0.00140356645</inTangent>
                <outTangent>-0.00140356645</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.082358636</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.39024958</value>
                <inTangent>-0.0116548538</inTangent>
                <outTangent>-0.0116548538</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.378594726</value>
                <inTangent>-0.005827427</inTangent>
                <outTangent>-0.005827427</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.378594726</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
      </item>
    </curves>
    <name>Kick2</name>
    <attacking>LeftLeg</attacking>
    <defending>RightLeg</defending>
    <length>0.6</length>
  </kick2>
  <kick3>
    <curves>
      <item>
        <key>
          <string>Armaturem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armaturem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.153329134</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.450936079</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.4166155</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.492752552</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.4847362</value>
                <inTangent>-0.108177066</inTangent>
                <outTangent>-0.108177066</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.268382072</value>
                <inTangent>-0.1820823</inTangent>
                <outTangent>-0.1820823</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.120571613</value>
                <inTangent>-0.147810459</inTangent>
                <outTangent>-0.147810459</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.41201258</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.6042012</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.568222761</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.6576625</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.646403134</value>
                <inTangent>-0.445113152</inTangent>
                <outTangent>-0.445113152</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.53662944</value>
                <inTangent>-0.470678777</inTangent>
                <outTangent>-0.470678777</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.58776069</value>
                <inTangent>-0.05113125</inTangent>
                <outTangent>-0.05113125</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hipm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9964251</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.999893844</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.8002662</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.5408311</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.718941152</value>
                <inTangent>-0.008124679</inTangent>
                <outTangent>-0.008124679</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.7026918</value>
                <inTangent>0.0381044447</inTangent>
                <outTangent>0.0381044447</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.795150042</value>
                <inTangent>0.09245825</inTangent>
                <outTangent>0.09245825</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.08448148</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.014571107</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.5996449</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.8411312</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.695071</value>
                <inTangent>-0.008211702</inTangent>
                <outTangent>-0.008211702</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.7114944</value>
                <inTangent>0.0443291068</inTangent>
                <outTangent>0.0443291068</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.606412768</value>
                <inTangent>0.105081618</inTangent>
                <outTangent>0.105081618</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.003</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomachm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.0298899263</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.0183558073</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.7660443</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.311476737</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.016934216</value>
                <inTangent>0.008191168</inTangent>
                <outTangent>0.008191168</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.0333165526</value>
                <inTangent>0.3745551</inTangent>
                <outTangent>0.3745551</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.766044438</value>
                <inTangent>0.7327279</inTangent>
                <outTangent>0.7327279</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.999553263</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.999831557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.642787755</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.9502538</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.999856651</value>
                <inTangent>0.000205904245</inTangent>
                <outTangent>0.000205904245</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.999444842</value>
                <inTangent>0.178534508</inTangent>
                <outTangent>0.178534508</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.642787635</value>
                <inTangent>0.3566572</inTangent>
                <outTangent>0.3566572</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.003000024</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.424999923</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chestm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.8724703</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.383269966</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.8275372</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.269328535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.0823131353</value>
                <inTangent>0.1250965</inTangent>
                <outTangent>0.1250965</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.456520736</value>
                <inTangent>-0.532532454</inTangent>
                <outTangent>-0.532532454</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.9827517</value>
                <inTangent>-0.526231</inTangent>
                <outTangent>-0.526231</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.48866716</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9236364</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.5614109</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.9630484</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.9966066</value>
                <inTangent>0.02675274</inTangent>
                <outTangent>0.02675274</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.8897128</value>
                <inTangent>0.590768337</inTangent>
                <outTangent>0.590768337</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.184930086</value>
                <inTangent>1.0746429</inTangent>
                <outTangent>1.0746429</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.04098285</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.5165124</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.7950065</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.5589145</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.8904926</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.6115055</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.0142445546</value>
                <inTangent>0.0148634613</inTangent>
                <outTangent>0.0148634613</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.7899492</value>
                <inTangent>-0.424869865</inTangent>
                <outTangent>-0.424869865</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.8639843</value>
                <inTangent>-0.07403511</inTangent>
                <outTangent>-0.07403511</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.60660094</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.829225361</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.454997748</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.7912402</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.999898553</value>
                <inTangent>-9.208918E-06</inTangent>
                <outTangent>-9.208918E-06</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.6131723</value>
                <inTangent>-0.7517087</inTangent>
                <outTangent>-0.7517087</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.5035188</value>
                <inTangent>0.109653473</inTangent>
                <outTangent>0.109653473</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.003244847</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.00288165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.289763957</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9730542</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.99439925</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.9561138</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.7930893</value>
                <inTangent>-0.05692765</inTangent>
                <outTangent>-0.05692765</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.54157573</value>
                <inTangent>-0.0263404548</inTangent>
                <outTangent>-0.0263404548</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.8457702</value>
                <inTangent>-0.30419445</inTangent>
                <outTangent>-0.30419445</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9570981</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.230576932</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.105689257</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.292995721</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.6091054</value>
                <inTangent>0.09392768</inTangent>
                <outTangent>0.09392768</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.840652</value>
                <inTangent>0.5713264</inTangent>
                <outTangent>0.5713264</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.533547342</value>
                <inTangent>-0.307104647</inTangent>
                <outTangent>-0.307104647</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.16</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHandm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperLeftArm/LowerLeftArm/LeftHand</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.6393855</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.23510766</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.829289</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.329459429</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.09504691</value>
                <inTangent>0.185614184</inTangent>
                <outTangent>0.185614184</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.324353218</value>
                <inTangent>-0.5357639</inTangent>
                <outTangent>-0.5357639</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.9764809</value>
                <inTangent>-0.6521277</inTangent>
                <outTangent>-0.6521277</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.7688863</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9719693</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.55882</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.94416976</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.995472848</value>
                <inTangent>0.055416584</inTangent>
                <outTangent>0.055416584</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.9459361</value>
                <inTangent>0.3899344</inTangent>
                <outTangent>0.3899344</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.215604067</value>
                <inTangent>0.730332</inTangent>
                <outTangent>0.730332</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.02179237</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.5589425</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.842525542</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.0196500439</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.891359031</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.724101067</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.0155988336</value>
                <inTangent>0.07144892</inTangent>
                <outTangent>0.07144892</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.7522657</value>
                <inTangent>-0.498238385</inTangent>
                <outTangent>-0.498238385</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.980877936</value>
                <inTangent>-0.228612244</inTangent>
                <outTangent>-0.228612244</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.5386565</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.999806941</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.453298151</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.6896939</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.9998784</value>
                <inTangent>-0.00625944138</inTangent>
                <outTangent>-0.00625944138</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.658859849</value>
                <inTangent>-0.5972513</inTangent>
                <outTangent>-0.5972513</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.19462423</value>
                <inTangent>0.4642356</inTangent>
                <outTangent>0.4642356</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.00324699283</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.00288033</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArmm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.0103803268</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.958269835</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.99426955</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.978316545</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.7825829</value>
                <inTangent>-0.06269154</inTangent>
                <outTangent>-0.06269154</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.36098665</value>
                <inTangent>-0.0114495754</inTangent>
                <outTangent>-0.0114495754</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.80548203</value>
                <inTangent>-0.44449538</inTangent>
                <outTangent>-0.44449538</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.9999462</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.285865128</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.106902227</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.207115531</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.6225465</value>
                <inTangent>0.101751268</inTangent>
                <outTangent>0.101751268</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.932571</value>
                <inTangent>0.6075834</inTangent>
                <outTangent>0.6075834</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.592620254</value>
                <inTangent>-0.33995074</inTangent>
                <outTangent>-0.33995074</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.006</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.213</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHandm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/UpperRightArm/LowerRightArm/RightHand</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>7.45058E-08</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>7.82310963E-08</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1.1920929E-07</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-8.94069743E-08</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.490116E-07</value>
                <inTangent>8.940696E-08</inTangent>
                <outTangent>8.940696E-08</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>2.98023224E-08</value>
                <inTangent>2.98023153E-08</inTangent>
                <outTangent>2.98023153E-08</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-8.940697E-08</value>
                <inTangent>-1.1920929E-07</inTangent>
                <outTangent>-1.1920929E-07</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.009811555</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.623954535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/Stomach/Chest/Headm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/Stomach/Chest/Head</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.7576053</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.320348084</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.9407415</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.8425655</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.6948801</value>
                <inTangent>0.0573550463</inTangent>
                <outTangent>0.0573550463</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.7043112</value>
                <inTangent>0.036095202</inTangent>
                <outTangent>0.036095202</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.767070532</value>
                <inTangent>0.06275934</inTangent>
                <outTangent>0.06275934</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.652712941</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.9472999</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.3391246</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.5385939</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.719125569</value>
                <inTangent>-0.06606507</inTangent>
                <outTangent>-0.06606507</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.7098914</value>
                <inTangent>-0.0387813449</inTangent>
                <outTangent>-0.0387813449</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.6415629</value>
                <inTangent>-0.0683285</inTangent>
                <outTangent>-0.0683285</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.8001964</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.393132657</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.943167746</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00120946765</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00735190557</value>
                <inTangent>0.00308239483</inTangent>
                <outTangent>0.00308239483</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.494322181</value>
                <inTangent>0.41887176</inTangent>
                <outTangent>0.41887176</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.830391645</value>
                <inTangent>1.32471383</inTangent>
                <outTangent>1.32471383</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.599738061</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.919481754</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.3323172</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.9999993</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.999973</value>
                <inTangent>1.31428242E-05</inTangent>
                <outTangent>1.31428242E-05</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.8692788</value>
                <inTangent>-0.7785766</inTangent>
                <outTangent>-0.7785766</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.557180166</value>
                <inTangent>-1.426459</inTangent>
                <outTangent>-1.426459</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.00287557</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.06793383</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.0778603256</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.007226795</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.841913462</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.6895744</value>
                <inTangent>-0.0596592128</inTangent>
                <outTangent>-0.0596592128</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.2613277</value>
                <inTangent>0.292111218</inTangent>
                <outTangent>0.292111218</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.10535197</value>
                <inTangent>0.155975744</inTangent>
                <outTangent>0.155975744</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.997689843</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9969643</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.999973953</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.5396126</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.724214852</value>
                <inTangent>-0.06812936</inTangent>
                <outTangent>-0.06812936</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.965250134</value>
                <inTangent>-0.859324932</inTangent>
                <outTangent>-0.859324932</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.994434953</value>
                <inTangent>-1.95968509</inTangent>
                <outTangent>-1.95968509</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.168</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFootm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperRightLeg/LowerRightLeg/RightFoot</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.2417592</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.0235140175</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.835401535</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.866968751</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.7715379</value>
                <inTangent>0.0418505967</inTangent>
                <outTangent>0.0418505967</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.75723803</value>
                <inTangent>-0.2854815</inTangent>
                <outTangent>-0.2854815</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.200574875</value>
                <inTangent>-0.556663156</inTangent>
                <outTangent>-0.556663156</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.970336258</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.999723554</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.5496402</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.498362541</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.6361833</value>
                <inTangent>-0.0589748621</inTangent>
                <outTangent>-0.0589748621</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.653139</value>
                <inTangent>0.171747535</inTangent>
                <outTangent>0.171747535</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.9796784</value>
                <inTangent>0.3265394</inTangent>
                <outTangent>0.3265394</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.00285469</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.09441422</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.794790745</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.324538827</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.995577157</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.005651474</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.0401250161</value>
                <inTangent>0.0190202761</inTangent>
                <outTangent>0.0190202761</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.7684517</value>
                <inTangent>0.433782548</inTangent>
                <outTangent>0.433782548</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.8274401</value>
                <inTangent>0.0589883924</inTangent>
                <outTangent>0.0589883924</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.606883645</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9458724</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.09394744</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.9999841</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.9991947</value>
                <inTangent>0.0004015863</inTangent>
                <outTangent>0.0004015863</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.639907837</value>
                <inTangent>-0.780374348</inTangent>
                <outTangent>-0.780374348</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.5615541</value>
                <inTangent>0.07835376</inTangent>
                <outTangent>0.07835376</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.00324639678</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.00287521</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLegm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.6244946</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.302207857</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.468725383</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.864138365</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.7453897</value>
                <inTangent>-0.0543836653</inTangent>
                <outTangent>-0.0543836653</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.0173431952</value>
                <inTangent>0.0236991048</inTangent>
                <outTangent>0.0236991048</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.6979915</value>
                <inTangent>-0.680648267</inTangent>
                <outTangent>-0.680648267</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.781029165</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9532421</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.883344</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.503254235</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.666629</value>
                <inTangent>-0.07330692</inTangent>
                <outTangent>-0.07330692</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.9998496</value>
                <inTangent>-0.6913675</inTangent>
                <outTangent>-0.6913675</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.716106057</value>
                <inTangent>0.28374356</inTangent>
                <outTangent>0.28374356</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.164</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFootm_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Armature/Hip/UpperLeftLeg/LowerLeftLeg/LeftFoot</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.313880682</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.584244251</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.278164625</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-2.00919771</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-2.61976814</value>
                <inTangent>0.1311698</inTangent>
                <outTangent>0.1311698</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.252567053</value>
                <inTangent>1.74262834</inTangent>
                <outTangent>1.74262834</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.8654885</value>
                <inTangent>1.11805558</inTangent>
                <outTangent>1.11805558</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.75954366</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>2.13060474</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.422213435</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.1355604</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.08478117</value>
                <inTangent>-0.00190937519</inTangent>
                <outTangent>-0.00190937519</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-2.810822</value>
                <inTangent>-0.701909363</inTangent>
                <outTangent>-0.701909363</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.4885999</value>
                <inTangent>1.32222211</inTangent>
                <outTangent>1.32222211</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Arm Handle/New Spritem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Arm Handle/New Sprite</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.832026</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.7799709</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.368668318</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-1.82483768</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-2.713471</value>
                <inTangent>0.2775972</inTangent>
                <outTangent>0.2775972</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.7301903</value>
                <inTangent>1.533654</inTangent>
                <outTangent>1.533654</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.353837</value>
                <inTangent>1.08402729</inTangent>
                <outTangent>1.08402729</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.26230574</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>2.203463</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.4779719</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.0313943624</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.0164945126</value>
                <inTangent>0.0917266</inTangent>
                <outTangent>0.0917266</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-2.95650864</value>
                <inTangent>-0.621743262</inTangent>
                <outTangent>-0.621743262</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.259981</value>
                <inTangent>1.6965276</inTangent>
                <outTangent>1.6965276</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Arm Handle/New Sprite (1)m_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Arm Handle/New Sprite (1)</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.8235717</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-1.07930326</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.461067677</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>2.503854</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>2.63121247</value>
                <inTangent>-0.1381973</inTangent>
                <outTangent>-0.1381973</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1.217318</value>
                <inTangent>-1.642711</inTangent>
                <outTangent>-1.642711</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.6542096</value>
                <inTangent>-1.87152767</inTangent>
                <outTangent>-1.87152767</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-2.65</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-2.65</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.9210343</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.399914622</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.422639132</value>
                <inTangent>-0.06359562</inTangent>
                <outTangent>-0.06359562</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-2.65</value>
                <inTangent>-1.10638881</inTangent>
                <outTangent>-1.10638881</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-2.63541675</value>
                <inTangent>0.0145833492</inTangent>
                <outTangent>0.0145833492</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Left Leg Handle/New Spritem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Left Leg Handle/New Sprite</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.677718163</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.0286226273</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.206190825</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>2.58118176</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>2.77285743</value>
                <inTangent>-0.139412045</inTangent>
                <outTangent>-0.139412045</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1.85722768</value>
                <inTangent>-0.9560789</inTangent>
                <outTangent>-0.9560789</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.860699654</value>
                <inTangent>-0.996528</inTangent>
                <outTangent>-0.996528</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-2.51875019</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-2.65</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-1.060417</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.208415508</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.841667056</value>
                <inTangent>-0.0336672962</inTangent>
                <outTangent>-0.0336672962</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-2.65</value>
                <inTangent>-0.9041665</inTangent>
                <outTangent>-0.9041665</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-2.65</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Right Leg Handle/New Sprite (1)m_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Right Leg Handle/New Sprite (1)</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>1</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.153329134</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.450936079</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.4166155</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0.492752552</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.4847362</value>
                <inTangent>-0.108177066</inTangent>
                <outTangent>-0.108177066</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.268382072</value>
                <inTangent>-0.1820823</inTangent>
                <outTangent>-0.1820823</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.120571613</value>
                <inTangent>-0.147810459</inTangent>
                <outTangent>-0.147810459</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-1.41201258</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.6042012</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.568222761</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.6576625</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.646403134</value>
                <inTangent>-0.445113152</inTangent>
                <outTangent>-0.445113152</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-1.53662944</value>
                <inTangent>-0.470678777</inTangent>
                <outTangent>-0.470678777</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-1.58776069</value>
                <inTangent>-0.05113125</inTangent>
                <outTangent>-0.05113125</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.114226721</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.0329224281</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.227594882</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.6308317</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.6827965</value>
                <inTangent>-0.0024458468</inTangent>
                <outTangent>-0.0024458468</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.6876882</value>
                <inTangent>0.45106113</inTangent>
                <outTangent>0.45106113</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.219325781</value>
                <inTangent>0.907013953</inTangent>
                <outTangent>0.907013953</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalRotation.w</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalRotation.w</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>-0.9934547</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>-0.9994579</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.973755956</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.7759197</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.730608642</value>
                <inTangent>0.00230121613</inTangent>
                <outTangent>0.00230121613</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.7260062</value>
                <inTangent>-0.12252152</inTangent>
                <outTangent>-0.12252152</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.9756517</value>
                <inTangent>-0.249645472</inTangent>
                <outTangent>-0.249645472</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalPosition.x</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalPosition.x</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.082358636</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.0240874644</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>-0.0456712581</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.3690918</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>-0.397777</value>
                <inTangent>0.00179193914</inTangent>
                <outTangent>0.00179193914</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>-0.3941931</value>
                <inTangent>0.17381312</inTangent>
                <outTangent>0.17381312</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>-0.05015076</value>
                <inTangent>0.344042361</inTangent>
                <outTangent>0.344042361</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalPosition.y</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalPosition.y</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0.378594726</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0.395564169</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0.248008341</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>-0.0134131135</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0.02643615</value>
                <inTangent>-0.00537566934</inTangent>
                <outTangent>-0.00537566934</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0.0156848114</value>
                <inTangent>0.111097008</inTangent>
                <outTangent>0.111097008</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0.248630166</value>
                <inTangent>0.232945353</inTangent>
                <outTangent>0.232945353</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
        <key>
          <string>Hip Handle/Head Handlem_LocalPosition.z</string>
        </key>
        <value>
          <Curve>
            <path>Hip Handle/Head Handle</path>
            <property>m_LocalPosition.z</property>
            <keys>
              <Keyframe>
                <time>0</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>1</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>2</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>3</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>4</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>5</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
              <Keyframe>
                <time>6</time>
                <value>0</value>
                <inTangent>0</inTangent>
                <outTangent>0</outTangent>
                <tangentMode>0</tangentMode>
              </Keyframe>
            </keys>
          </Curve>
        </value>
      </item>
    </curves>
    <name>Kick3</name>
    <attacking>LeftLeg</attacking>
    <defending>RightLeg</defending>
    <length>1</length>
  </kick3>
</CharacterData>`;

export default code;