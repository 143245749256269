import React from 'react';
import './Skill.css';

function Skill(props) {
    return(
        <div className="skill">
            <i className={ "skill-icon " + props.icon }></i>
            <div className="skill-title">
                {props.name}
            </div>
            <div className="skill-content">
                {props.text}
            </div>
        </div>
    );
}

export default Skill