import React from 'react';
import code from '../Components/FF-CharacterText.js';
import './Projects.css';
import editorImg from '../img/ff_editor.gif';
import attackImg from '../img/ff_attack.gif';
import menuImg from '../img/ff_menu.gif';

function FigureFighter() {
    return (
        <div className="row m-0 content">
            <div className="col content-col">
                <h1 className="w-100 header">Figure Fighter</h1>               
                <h3>The Challenge</h3>
                <p>
                    <h5>The Purpose</h5>
                    Have you ever seen one of those stick figure fighting animations? These
                    tickle my nostalgia like nothing else. They're the ideal manifest of my
                    6th grade notebook doodles being brought to life. Short form animations
                    and videos have been all the rage and a popular form of content on the
                    internet. From flash animations, self hosted videos, to the now monolithic
                    content aggregator that is YouTube, the internet has long favored eye-grabbing
                    content that can be produced by individuals or small groups. In such an
                    environment the popularity of the stick figure animation is a no brainer
                    due to high energy and simplicity in its low production cost.
                </p>
                <p>
                    I spent quite a bit of time playing with Pivot when I was younger. Pivot
                    is a program tailored to make stick figure animations and a go-to tool for
                    a brawl of such. Now seeing as I never uploaded or saved anything, nothing I
                    made with that program would exist today, let alone be worthwhile for showing.
                    Figure Fighter on the other hand was born from this concept which is my project
                    I will show off now. With Figure Fighter, I sought to take the core concept of
                    bringing your doodle to life, and add a new dimension to it via gamification. I
                    imagined a game with a character creator where you created your character via
                    their actions rather than appearance.
                </p>
                <img src={menuImg} alt="" className="col-image" />
                <p>
                    <h5>The Project</h5>
                    The core of this project lied with the animations. In its simplest terms, an
                    animation is a collection of time stamped poses. We refer to each pose as a keyframe,
                    which is in itself a collection of relative positions and rotations for each
                    bone/joint. I needed to create an editor for animation creation, a way to save the
                    animations, and a way to play them back later in a game setting. All 3 of these
                    things pose their own set of challenges, but if all 3 were completed the concept
                    would be minimally realized. So I defined the mvp to be having these 3 concepts
                    functional and tied together in a single product. To really sell the whole "doodle
                    brought to life" idea that last point about the game setting point should really be
                    expanded on. However, my goal here was more of a proof of concept rather than a fully
                    fledged game so I moved forward with the stated goal in mind.
                </p>
                <h3>The Solution</h3>
                <p>
                    <h5>The Editor</h5>
                    <img src={editorImg} alt="" className="col-image" />
                    The character editor is the biggest part of the gameplay (if you can really call it gameplay) 
                    and the most visually interesting part of the program. The editor has a set of controls and two 
                    depictions of stick figures. The left figure can be manipulated along with the controls in the 
                    center to define the poses/keyframes of the animation. You can define a couple other things as
                    well which will be discussed with the game setting. The right figure will show the
                    calculated output animation on a stick character. I also made a dev feature that allowed
                    me to save an animation for use at development time. This tool was handy for stock
                    animations, like walking and stock enemies, which may be needed for each character on screen.
                </p>
                <p>
                    <h5>The Game Setting</h5>
                    I created a set of rules to define the game setting with an extremely simple world that would be
                    populated with the player and hostile NPC characters. The rules were simple:
                    <img src={attackImg} alt="" className="inline-image" />
                    <ol>
                        <li>There are two types of attacks: punches and kicks</li>
                        <ol>
                            <li>Each attack type has a pool of 3 attacks with a dedicated button to each type</li>
                            <li>Each attack type would independently cycle through its respective pool of attacks</li>
                        </ol>
                        <li>Each attack would define either the left or right limb as offense (red), and the other limb as defense (blue)</li>
                        <li>Collision is ignored except in the cases of the offensive limbs</li>
                        <ol>
                            <li>If an offensive limb hits a defensive limb, the animation is cancelled and no damage is applied</li>
                            <li>If the offensive limb hits any hitbox that is NOT a defensive limb, then damage is applied and the animation finishes</li>
                        </ol>
                        <li>Each character has 3 hit points</li>
                    </ol>
                </p>
                <p>
                    <h5>The Animations</h5>
                    I previously stated that the animations were the core of the project, so let's talk about
                    them a little bit. The foremost feature required was a way to serialize the data into text.
                    Which simply meant recursively recording the position and rotation of each transform in the
                    object's hierarchy and the relative timestamp, for each keyframe, in a text formatted file.
                    Once I defined that I needed a way to make it play with Unity's animation system. The
                    AnimationClip class provided a way to set data, so the deserialization process was defined
                    as reading the serialized data and creating an AnimationClip object as the output. To play
                    the animations, I created a custom character controller with fields for the 6 attack animations
                    plus any of the stock animations like the walking one I mentioned. This has hopefully changed
                    by now but at the time the Animator component from the mecanim system was having trouble
                    interacting with animations generated at runtime, so I opted to use the legacy animation
                    component which was another reason I needed the custom controller. So now that I could make,
                    store, and use my animations, the project was just about complete
                </p>
                <p>
                    Here is what a serialized animation looks like. The file ends up being extremely long due to 
                    the amount of data being processed and stored, so it is important to be smart about how often you
                    serialize/deserialize.
                    <pre><code>
                        { code }                      
                    </code></pre>                
                </p>
                <h3>Wrapping Up</h3>
                <p>
                    With all the major components completed I just needed to put them together. I created a simple 
                    main menu with a character selector and minimal instructions, and 2 options to choose from. With 
                    your character selected you could pick either to start the game, or to edit the character. The 
                    former option would spawn the game world (a huge 2D plane) with you in the center and enemies 
                    will start to spawn just off screen to give chase to the player. The latter option simply loads 
                    the editor and initializes it with the values of the selected character's animations.
                </p>
            </div>
        </div>
        );
}

export default FigureFighter;