import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <div className="row m-0 w-100 footer-area">
            <div className="media-buttons">
                <a href="https://github.com/sharpshot124">
                    <i className="fab fa-github-square media-icon"></i>
                </a>
                <a href="mailto: sharpshot124@gmail.com">
                    <i className="far fa-envelope  media-icon"></i>
                </a>
            </div>
        </div>
    );
}

export default Footer;