import React from 'react';
import './Splash.css';
import splash from '../img/coverr-hands-typing-on-a-keyboard-7861.mp4'

function Splash() {
    return(
        <div className="splash-background">
            <video className="video" src={splash} preload="auto" autoPlay muted loop></video>
            <h1 className="name">
                Chrsitian Carlburg 
            </h1>
            <h2 className="tagline">
                Software Developer              
            </h2>
            <h2 className="tagline">
                .Net | Game Design | Front End
            </h2>
            
        </div>
    );
}

export default Splash