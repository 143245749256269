import React from 'react';
import './Home.css';
import Splash from '../Components/Splash.js';
import About from '../Components/About.js';
import ProjectCarousel from '../Components/ProjectCarousel.js';
import '@fortawesome/fontawesome-free/css/all.css'

import ccImg from '../img/image5.png';
import ffImg from '../img/ff_editor.gif';

function Home() {
    const projectThumbnails =
        [
            <a href="/projects/CraftCraft">
                <div className="w-100 carousel-slide-background">
                    <img className="carousel-slide-content" src={ccImg} alt="CraftCraft" />
                </div>
            </a>,
            <a href="/projects/DSP">
                <div className="w-100 carousel-slide-background">
                    <div className="embed-container">
                        <iframe src='https://www.youtube.com/embed/feFyUehqtqc' frameBorder='0' title="Money (Cover)" allowFullScreen></iframe>
                    </div>
                </div>
            </a>,
            <a href="/projects/FigureFighter">
                <div className="w-100 carousel-slide-background">
                    <img className="carousel-slide-content" src={ffImg} alt="Figure Fighter" />
                </div>
            </a>
        ];

    return(
        <div className="col-lg-12 p-0">
            <Splash id="home"/>
            <div className="Carousel w-100">
                <h3 className="title">Projects</h3>
                <ProjectCarousel slides= { projectThumbnails } />
            </div>
            <About />
        </div>
    );
}

export default Home