import React from 'react';
import './Project-DSP.css';

function ProjectDSP() {

    return(
        <div className="row m-0 content">         
            <h1 className="w-100 header">Digital Signal Processing (DSP)</h1>

            <div className="col-lg-3">
                <div className="embed-container-wrap">
                    <div className="embed-container">
                        <iframe src='https://www.youtube.com/embed/feFyUehqtqc' frameBorder='0' title="Video" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 content-text">                    
                <p>
                    DSP refers to the usage of a stream of data usually from a realtime source.
                    There are many aspects to how this data can be propogated, modulated, and utilized. One of the most 
                    basic of demonstrations of a signal would be the readout seen the set of bars often displayed for audio
                    equalizers. The video shown here is a video I created using that concept to create an effect to visualize
                    the audio signal from different perspectives. The code for the demonstration is quite simple because it 
                    was created in unity which does the heavy lifting for me. So lets talk about what it's doing for us, and
                    I used it to this effect.
                </p>
                <h3>Fast Fourier transform (FFT)</h3>
                <p>
                    Digital Signal Processing (DSP) refers to the usage of a stream of data usually from a realtime source.
                    There are many aspects to how this data can be propogated, modulated, and utilized. One of the most
                    basic of demonstrations of a signal would be the readout seen the set of bars often displayed for audio
                    equalizers. The video shown here is a demonstration using that concept to create an effect to visualize
                    the audio signal from different perspectives. The code for the demonstration is quite simple because it
                    was created in unity which does the heavy lifting for me. So lets talk about what it's doing for us, and
                    I used it to this effect.
                </p>
                <h3>The Components</h3>
                <p>
                    Digital Signal Processing (DSP) refers to the usage of a stream of data usually from a realtime source.
                    There are many aspects to how this data can be propogated, modulated, and utilized. One of the most
                    basic of demonstrations of a signal would be the readout seen the set of bars often displayed for audio
                    equalizers. The video shown here is a demonstration using that concept to create an effect to visualize
                    the audio signal from different perspectives. The code for the demonstration is quite simple because it
                    was  created in unity which does the heavy lifting for me. So lets talk about what it's doing for us, and
                    I used it to this effect.
                </p>
                <h3>Putting it together</h3>
                <p>
                    Digital Signal Processing (DSP) refers to the usage of a stream of data usually from a realtime source.
                    There are many aspects to how this data can be propogated, modulated, and utilized. One of the most
                    basic of demonstrations of a signal would be the readout seen the set of bars often displayed for audio
                    equalizers. The video shown here is a demonstration using that concept to create an effect to visualize
                    the audio signal from different perspectives. The code for the demonstration is quite simple because it
                    was created in unity which does the heavy lifting for me. So lets talk about what it's doing for us, and
                    I used it to this effect.
                </p>
            </div>
            <div className="col-xl-3"> </div>
        </div>
    );
}

export default ProjectDSP;