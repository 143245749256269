import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import './App.css';
import Navbar from './Components/Navbar.js';
import Footer from './Components/Footer.js';
import Home from './Pages/Home.js';
import ProjectDSP from './Pages/Project-DSP.js';
import CraftCraft from './Pages/CraftCraft.js';
import FigureFighter from './Pages/FigureFighter.js';
import OtherProjects from './Pages/OtherProjects.js';

function App() {

    return (
        <Router>
            <div className="container-fluid p-0">
                <Navbar />
                
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/projects/DSP">
                        <ProjectDSP />
                    </Route>
                    <Route path="/projects/CraftCraft">
                        <CraftCraft />
                    </Route>
                    <Route path="/projects/FigureFighter">
                        <FigureFighter />
                    </Route>
                    <Route path="/projects/OtherTestimonials">
                        <OtherProjects />
                    </Route>
                </Switch>

                <Footer />
            </div>
        </Router>
    );
}

export default App;
