import React from 'react';
import './Projects.css';
import FiverrTestimonial from '../img/Fiverr.png';

function OtherProjects() {
    return (
        <div className="row m-0 content">
            <div className="col content-col">
                <h1 className="w-100 header">Other Projects and Testimonials</h1>               
                <h3>Red Alliance</h3>
                <p>
                    Red Alliance is a story driven indie FPS game. Most of its features were built 
                    from the ground up, in house. I contributed code, design, and consultation for 
                    many of the systems found in the game. Notable contributions include 
                    the gunplay/character controllers, projectile system, and saving system. This project 
                    afforded me many opportunities to learn, and contributing to a shipped game ticks 
                    a big checkbox for both my resume and my bucket list. If you're into indie games and you want a 
                    game that pays homage to the Half Life series, make sure to check out the passion
                    project <a href="https://store.steampowered.com/app/594050/Red_Alliance">Red Alliance</a>.
                </p>
                <img className="col-image" src="https://steamcdn-a.akamaihd.net/steam/apps/594050/ss_c6a1ce60bb16b97ad0ca3c0fce4aa6ee2787e090.jpg?t=1570119553" alt="Red Alliance"/>

                <br />
                <h3>CashCrunch Prototype</h3>
                <p>
                    Cash Crunch is a children's board game intended to teach money handling skills and 
                    create opportunities for discussions of financial topics. The creator sought to create 
                    an electronic version of the experience and I was contracted in that effort. My role 
                    was to create a MVP product to be used internally for demonstration purposes and continued 
                    development by a larger studio. The main task of this project was to create a 
                    barebones codebase that was easily extendible and efficient enough for reuse. Once the 
                    code was built up, I had to put together a variety of experiences that would be indicative 
                    of what you might see in game. This included UI, minigame(s), basic animations, and scene 
                    setups. After a few tweaks and iterations, I was ready to put together the documentation, 
                    create some demo resources, and deliver to the client. This was a simple in and out job 
                    with a good result and a fantastic client testimonial. I hope I get to see what form the 
                    product will take under different hands.
                </p>
                <img className="col-image" src={FiverrTestimonial} alt="Fiverr Review"/>

                <br />
                <h3>SenteAutomation</h3>
                <p>
                    One of my previous job titles was a "Logistics Specialist" and typically that would refer 
                    to a warehouse worker. My role was of a similar nature to that, except that I worked in a lab environment 
                    and the products which I worked with was metrology and test equipment that was often quite 
                    expensive. As such, there was quite a bit of data entry included with this job in order to track 
                    the frequent movements. I recognized the opportunity and desire for automation with this data 
                    entry as the provided UX of our proprietary database was lackluster to say the least. However 
                    this was a hostile development situation that would prove a challenge to overcome in an effective 
                    way. Since my position was not directly related to the digital product, I was stepping outside my 
                    role by making such a piece of software and I would not be granted any access or resources to develop. 
                    I ended up creating a utility that would automate the web browser directly using the Selenium library. 
                    This was a sub-par solution, but it could be accomplished without generating any requests to the 
                    database that wasn't traditionally propagated, and could be developed with minimal testing since my 
                    test data was live. I was able to share this tool with my team and continue development to add even 
                    more time saving features as work progressed. This utility provided automation for a number of tasks 
                    which I am unable to elaborate on without divulging details, but the time saved amounted up to 3 hours 
                    a day per worker in ideal circumstances; and 1 hour on a typical day.
                </p>           
            </div>
        </div>
        );
}

export default OtherProjects;